import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import ScoreCardBuilder from '../canvas/ScoreCardBuilder';

class NewScoreCard extends React.Component{ 
  constructor(props){
    super(props);
    this.state = {
      scoreCard: null,
      scoreBuilderOpen: false
    }
    this.saveScores        = this.saveScores.bind(this);
  }

  componentDidMount(){
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
  }

  saveScores(club, scoreCard){
    this.setState({ 
      scoreBuilderOpen: false, 
      scoreCard: scoreCard
    }, () => window.location = this.props.success_path)
  }

  render() {
    const { scoreBuilderOpen, scoreCard } = this.state
    const { save_score_card_path, user } = this.props

    return (
      <div className="">
        <ScoreCardBuilder 
            user={user && user}
            scoreCard={scoreCard }
            save_score_card_path={save_score_card_path}
            saveScores={this.saveScores}
            scoreBuilderOpen={scoreBuilderOpen}
            setLoading={() => {}}
            closeScoreBuilder={() => this.setState({scoreBuilderOpen: false})}
            passClearStateFunc={() => {}}
          />
        <button
          type="button"
          onClick={() => this.setState({scoreBuilderOpen: true})}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
        >
          <span>Add Scorecard</span> 
        </button>
      </div>
    )
  }
}

export default NewScoreCard