import React from "react";
import ReactDOM from "react-dom";


class ClubSearch extends React.Component {
  constructor(props) {
    super(props);
  	this.state = {
  	}

  	this.onClubSelect = this.onClubSelect.bind(this);
  }
  componentDidMount() {
  }

  onClubSelect(){
    
  } 

  render(){
  	return(
  		<div className="">
  			TEST
  		</div>
  	)
  }
}

export default ClubSearch