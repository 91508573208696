import React from "react";
import ReactDOM from "react-dom";
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from "@heroicons/react/24/outline"
import Tabs from "./Tabs";
import TemplatesSlider from "./TemplatesSlider";

class Overlay extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }
  render() {
    return (

      <Transition.Root show={this.props.open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={this.props.closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300 sm:duration-500"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300 sm:duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            { this.props.currImage && 
                              <div>
                                <Dialog.Title className="text-lg  tracking-tight font-medium text-gray-900"> {this.props.currImage.artist.name} </Dialog.Title>
                                <div className="text-gray-400">{this.props.currImage.club.name}</div>
                              </div>
                            }

                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500"
                                onClick={this.props.closeModal}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative mt-6 flex-1">
                          { this.props.currImage && 
                            <div>
                              <div className="mt-3 mb-3 px-0 sm:px-6">
                                <img src={this.props.currCanvas ? this.props.currCanvas.image_preview : this.props.currImage.thumbnail} className="max-h-[35rem] mx-auto mb-0 sm:mb-4"/>
                              </div>
                              <Tabs/>
                              <TemplatesSlider 
                                currCanvas={this.props.currCanvas}
                                canvases={this.props.currImage.canvases}
                                onCanvasSelect={this.props.onCanvasSelect}
                              />
                            </div>
                          }
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        { this.props.currCanvas && 
                          <a
                            href={this.props.currCanvas.url}
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                          >
                            Customise this template
                          </a>
                        }
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


    );
  }
}


export default Overlay;