import React from "react";
import { Fragment } from "react";
import ReactDOM from "react-dom";
import { Transition, Dialog } from "@headlessui/react";
import { HeartIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Masonry from "@mui/lab/Masonry";

class UploadBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUpload: null,
      uploadModalOpen: false,
    };
    this.onUploadSelect = this.onUploadSelect.bind(this);
  }

  onUploadSelect(item) {
    this.setState({
      currentUpload: item,
      uploadModalOpen: true,
    });
  }

  render() {
    const { currentUpload, uploadModalOpen } = this.state;

    return (
      <div className="mx-10 max-w-6xl sm:mx-auto mt-8">
        <Masonry
          columns={{ xs: 2, sm: 3, md: 4 }}
          spacing={{ xs: 2, sm: 3, md: 4 }}
        >
          {this.props.uploads.map((item, index) => (
            <button
              onClick={(e) => this.onUploadSelect(item)}
              className="snap-left shrink-0 cursor-pointer"
              key={index}
            >
              <img
                src={item.thumbnail}
                className="group block w-full rounded-sm border bg-gray-100 overflow-hidden hover:opacity-75"
              />
            </button>
          ))}
        </Masonry>
        <DetailModal
          currentUpload={currentUpload}
          uploadModalOpen={uploadModalOpen}
          closeModal={(e) => this.setState({ uploadModalOpen: false })}
        />
      </div>
    );
  }
}

function DetailModal(props) {
  return (
    <Transition.Root show={props.uploadModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-0"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-0"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => props.closeModal()}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-scroll bg-white p-8">
                    {props.currentUpload && (
                      <div className="space-y-6 pb-16">
                        <div>
                          <div className="block w-full overflow-hidden rounded-lg">
                            <a href={props.currentUpload.original_url} className="cursor-pointer" target="_blank">
                              <img
                                src={props.currentUpload.thumbnail}
                                alt=""
                                className="object-cover"
                              />
                            </a>
                          </div>
                          <div className="mt-4 flex items-start justify-between">
                            <div>
                              <h2 className="text-lg font-medium text-gray-900">
                                <span className="sr-only">Details for </span>
                                {props.currentUpload.filename}
                              </h2>
                              <p className="text-sm font-medium text-gray-500">
                                {props.currentUpload.megabytes}MB
                              </p>
                            </div>
                            <button
                              type="button"
                              className="ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500"
                            >
                              <HeartIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Favorite</span>
                            </button>
                          </div>
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900">
                            Information
                          </h3>
                          <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                            <div className="flex justify-between py-3 text-sm font-medium">
                              <dt className="text-gray-500">Dimensions</dt>
                              <dd className="text-gray-900">
                                {props.currentUpload.dimensions}
                              </dd>
                            </div>
                            <div className="flex justify-between py-3 text-sm font-medium">
                              <dt className="text-gray-500">Type</dt>
                              <dd className="text-gray-900">
                                {props.currentUpload.resource_type}
                              </dd>
                            </div>
                            <div className="flex justify-between py-3 text-sm font-medium">
                              <dt className="text-gray-500">Uploaded by</dt>
                              <dd className="text-gray-900">Unknown</dd>
                            </div>
                            <div className="flex justify-between py-3 text-sm font-medium">
                              <dt className="text-gray-500">Created</dt>
                              <dd className="text-gray-900">
                                {props.currentUpload.created_at}
                              </dd>
                            </div>
                          </dl>
                        </div>

                        <div className="flex">
                          <a
                            href={props.currentUpload.original_url}
                            target="_blank"
                            className="flex-1 rounded-md text-center border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                          >
                            Download
                          </a>
                          <button
                            type="button"
                            className="ml-3 flex-1 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default UploadBrowser;
