import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import classNames from "classnames";

class Uploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentCompleted: 0,
      fileUploading: false,
    };
    this.onSuccessCallback = this.onSuccessCallback.bind(this);
    this.failureCallBack = this.failureCallBack.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount() {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
  }

  onSuccessCallback(result) {
    if (result.status === 200) {
      this.setState({ percentCompleted: 100 });

      const image = {
        public_id: result.data.public_id,
        width: result.data.width,
        height: result.data.height,
        path: result.data.path,
        format: result.data.format,
        filesize: result.data.bytes,
        filename: result.data.original_filename,
        resource_type: result.data.resource_type,
        duration: result.data.duration,
        eager_preview_url: result.data.eager[0].secure_url,
      };

      if (result.data.coordinates != null) {
        image.cx = result.data.coordinates.custom[0][0];
        image.cy = result.data.coordinates.custom[0][1];
        image.cw = result.data.coordinates.custom[0][2];
        image.ch = result.data.coordinates.custom[0][3];
      }

      axios.post(this.props.uploads_path, { upload: image }).then((res) => {
        this.props.uploadSuccess(res.data.upload);
        this.setState({ fileUploading: false });
        analytics.track("File Uploaded", {
          resource_type: image.resource_type,
        });
      });
    }
  }

  uploadFile(files) {
    this.setState({ fileUploading: true });

    const onUploadProgress = (event) => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total);
      this.setState({ percentCompleted: percentCompleted });
    };

    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "caddisnap");
    formData.append("folder", "UGC");

    axios
      .post("https://api.cloudinary.com/v1_1/caddisnap/upload", formData, {
        onUploadProgress,
      })
      .then((response) => {
        this.onSuccessCallback(response)
      });
  }

  failureCallBack(event) {
    console.log(event.error);
  }

  render() {
    const { upload, crop_ratio } = this.props;

    return (
      <div
        className={classNames(
          this.state.fileUploading ? "bg-green-600" : "bg-white",
          "text-center w-full p-4"
        )}
      >
        {!upload && !this.state.fileUploading && (
          <React.Fragment>
            <input
              className="hidden"
              type="file"
              id="upload-button"
              onChange={(event) => this.uploadFile(event.target.files)}
            />
            <label
              className="
              py-3 px-7
              rounded border-0
              block font-semibold
              bg-green-500 text-cream
              hover:bg-green-600 text-center
            "
              htmlFor="upload-button"
            >
              Choose file
            </label>
          </React.Fragment>
        )}

        {this.state.fileUploading && (
          <div className="relative pt-1">
            <img src={this.props.preloader_icon} className="max-w-[100px] mx-auto" />
            <div className="flex mb-2 items-center justify-between">
              <div>
                {this.state.percentCompleted == 100 ? (
                  <span className="text-xs tracking-wide font-semibold inline-block pl-2 py-1 uppercase rounded-full text-white ">
                    <span className="">
                      <UploadPreloader />
                    </span>{" "}
                    Finishing up
                  </span>
                ) : (
                  <span className="text-xs  tracking-wide font-semibold inline-block py-1 uppercase rounded-full text-yellow-500 animate-pulse">
                    Uploading...
                  </span>
                )}
              </div>
              <div className="text-right">
                <span
                  className={classNames(
                    this.state.percentCompleted == 100
                      ? "text-white"
                      : "text-yellow-500",
                    "text-xs font-semibold inline-block"
                  )}
                >
                  {this.state.percentCompleted}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-white">
              <div
                style={{ width: `${this.state.percentCompleted}%` }}
                className={classNames(
                  this.state.percentCompleted == 100
                    ? "bg-white"
                    : "bg-yellow-500",
                  "shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                )}
              ></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const UploadPreloader = (props) => (
  <svg
    className="inline animate-spin -ml-1 mr-1 h-4 w-4 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export default Uploader;
