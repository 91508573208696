import React from 'react';
import ReactDOM from 'react-dom';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class UploadSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      upload_id: this.props.upload_id
    };
    this.onUploadSelect = this.onUploadSelect.bind(this);
  }

  onUploadSelect(upload_id){
    this.setState({upload_id: upload_id, isOpen: false})
  }

  render() {
    return (
      <div>
        <UploadDialog
          selectedUpload={this.onUploadSelect}
          uploads={this.props.uploads}
          isOpen={this.state.isOpen}
          setClose={() => this.setState({isOpen: false})}/>
        <label className="mb-2 block leading-6 text-black cursor-default" for="art_layer_offset_y">Select an upload</label>

        <a onClick={() => this.setState({isOpen: true})} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mb-4 cursor-pointer">
          { !this.state.upload_id ? "Choose an upload" : `Upload: ${this.state.upload_id}`}
        </a>
        <div className="mb-3 hidden art_layer_upload_id">
          <input className="hidden" type="hidden" value={this.state.upload_id} name="art_layer[upload_id]" id="art_layer_upload_id" />
        </div>
      </div>  
    )
  }
}

function UploadDialog(props) {
  return (
    <Transition.Root show={props.isOpen} as={Fragment} appear>
      <Dialog as="div" className="relative z-50" onClose={props.setClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <section className="mt-8 pb-16 px-5" aria-labelledby="gallery-heading">
                <ul
                  role="list"
                  className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
                >
                  {props.uploads.map((upload) => (
                    <li key={upload.id} className="relative">
                      <div
                        className={classNames(
                          upload.current
                            ? 'ring-2 ring-offset-2 ring-green-500'
                            : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-green-500',
                          'group block w-full aspect-w-10 aspect-h-10 rounded-lg bg-gray-100 overflow-hidden'
                        )}
                      >
                        <img
                          src={upload.thumbnail}
                          alt=""
                          className={classNames(
                            upload.current ? '' : 'group-hover:opacity-75',
                            'object-cover pointer-events-none'
                          )}
                        />
                        <button type="button" className="absolute inset-0 focus:outline-none" onClick={(e) => props.selectedUpload(upload.id)}></button>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UploadSelector