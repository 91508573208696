import React from "react";
import ReactDOM from "react-dom";
import classNames from 'classnames';

export default function TemplatesSlider(props) {
  return (
    <div>
      <div>
        <div className="relative w-full flex gap-4 snap-x snap-mandatory overflow-x-auto pt-3 pb-4 bg-gray-100">
          {props.canvases.map((item, idx) => (
            <div className="snap-left shrink-0 first:pl-4 last:pr-4" key={idx} >
              <img src={item.image_preview} onClick={(e)=> props.onCanvasSelect(item)} 
                className={
                  classNames(
                    props.currCanvas == item ? 'ring-green-700' : 'ring-white',
                    'shrink-0 h-40 rounded-sm shadow-xl bg-white cursor-pointer ring-2 ring-offset-2 '
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
