import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { Fragment, useState } from "react";
import {
  PencilSquareIcon,
  MinusIcon,
  PlusIcon,
  CheckIcon,
  LocationMarkerIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  ArrowDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
} from "@heroicons/react/20/solid";
import { Combobox, RadioGroup, Transition, Dialog } from "@headlessui/react";
import axios from "axios";
import { DebounceInput } from "react-debounce-input";
import { DayPicker } from "react-day-picker";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  useHits,
} from "react-instantsearch-hooks-web";
import Preloader from "../global/Preloader";

const searchClient = algoliasearch(
  "527Q3PZ242",
  "1794c94f72461f174c50913819f3c496"
);

const MODE = {
  COURSE_SELECT: "course",
  TEE_SELECT: "tee",
  SCORE_SELECT: "score",
  CONFIRM_SELECT: "confirm",
};

class ScoreCardBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMode: MODE.COURSE_SELECT,
      currSearchQuery: "",
      currCourse: null,
      currScores: [],
      currPars: null,
      currTee: null,
      currDate: new Date(),
      backNineScores: false,
      searchLoading: false,
      currPlayerName: props.user && props.user.name,
      results: [],
    };
    this.setQuery = this.setQuery.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onTeeSelect = this.onTeeSelect.bind(this);
    this.onScoreChange = this.onScoreChange.bind(this);
    this.onScoreUp = this.onScoreUp.bind(this);
    this.onScoreDown = this.onScoreDown.bind(this);
    this.returnToSearch = this.returnToSearch.bind(this);
    this.returnToTeeSelect = this.returnToTeeSelect.bind(this);
    this.resetToDefault = this.resetToDefault.bind(this);
    this.scoresComplete = this.scoresComplete.bind(this);
    this.saveAndClose = this.saveAndClose.bind(this);
  }

  componentDidMount() {
    const { scoreCard } = this.props;

    if (scoreCard) {
      let currTee = scoreCard.course.tees.find(
        (tee) => tee.id == scoreCard.tee_id
      );

      this.setState({
        currCourse: scoreCard.course,
        currTee: currTee,
        currScores: currTee.scores,
        currPars: currTee.pars,
        currDate: new Date(scoreCard.played_at),
        currPlayerName: scoreCard.player_full_name,
        key: scoreCard.key,
        currentMode: MODE.CONFIRM_SELECT,
        isNineHole: scoreCard.course.holes != 18,
        backNineScores: false,
      });
    }

    this.props.passClearStateFunc(this.resetToDefault);
  }

  resetToDefault() {
    this.setState({
      results: [],
      currSearchQuery: "",
      currScores: [],
      currTee: null,
      currCourse: null,
      currPars: null,
      key: null,
    });
    this.returnToSearch();
  }

  returnToSearch() {
    this.setState({
      currentMode: MODE.COURSE_SELECT,
      backNineScores: false,
      currSearchQuery: "",
    });
  }

  returnToTeeSelect() {
    this.setState({
      currentMode: MODE.TEE_SELECT,
      currTee: null,
      backNineScores: false,
    });
  }

  setQuery(query, search) {
    this.setState({ currSearchQuery: query });
    search(query);
  }

  onSelect(hit) {
    const course = {
      id: parseInt(hit.objectID),
      name: hit.name_or_club,
      country: hit.club.country,
      city: hit.club.city,
      num_holes: hit.holes,
      url: `/courses/${hit.objectID}`,
    };
    this.props.setLoading(true);
    analytics.track("Course Search Result Selected", { name: hit.name_or_club, id: parseInt(hit.objectID) });
    this.setState({ currCourse: course }, () => this.getCourse(course));
  }

  onTeeSelect(tee) {
    this.setState(
      {
        currTee: tee,
        currPars: tee.pars,
      },
      () => this.setState({ currentMode: MODE.SCORE_SELECT })
    )
    analytics.track("Course Tee Selected", {id: tee.id})
  }

  onScoreChange(score, index) {
    let scores = this.state.currScores;
    scores[index] = parseInt(score);
    console.log(scores);
    this.setState({ currScores: scores });
  }

  onScoreUp(index) {
    let scores = this.state.currScores;

    if (scores[index]) {
      scores[index] = scores[index] + 1;
      this.setState({ currScores: scores });
    } else {
      scores[index] = this.state.currPars[index];
      this.setState({ currScores: scores });
    }
  }

  onScoreDown(index) {
    let scores = this.state.currScores;

    if (scores[index]) {
      if (scores[index] - 1 > 0) {
        scores[index] = scores[index] - 1;
        this.setState({ currScores: scores });
      }
    } else {
      scores[index] = this.state.currPars[index] - 1;
      this.setState({ currScores: scores });
    }
  }

  scoresComplete() {
    analytics.track("Course Scores Completed")
    console.log(this.state.scores);
    this.setState({ currentMode: MODE.CONFIRM_SELECT });
  }

  saveAndClose() {
    const { currDate, currScores, currTee, currCourse, currPlayerName, key } =
      this.state;
    const { user } = this.props;

    this.props.setLoading(true);

    let scoresDB = currScores.map((s, index) => {
      return { [`score_${index + 1}`]: currScores[index] };
    });

    let score_card_basics = {
      key: key,
      tee_id: currTee.id,
      player_full_name: currPlayerName,
      played_at: currDate.toLocaleDateString("en-GB"),
      user_id: user && user.id,
    };

    axios
      .post(this.props.save_score_card_path, {
        score_card: { ...score_card_basics, ...Object.assign({}, ...scoresDB) },
      })
      .then((res) => {
        this.setState({ score_card: res.data.score_card }, () =>
          this.props.saveScores(currCourse, res.data.score_card)
        );
        analytics.track("Score Card Saved", { key: res.data.score_card.key } );
      });
  }

  getCourse(course) {
    axios.get(course.url).then((res) => {
      console.log(res.data.course);
      this.setState({
        currCourse: res.data.course,
        currTee: null,
        currScores: this.props.defaultScores || [],
        currentMode: MODE.TEE_SELECT,
        isNineHole: res.data.course.holes != 18,
      });
      this.props.setLoading(false);
    });
  }

  render() {
    const {
      currentMode,
      currSearchQuery,
      results,
      currDate,
      currPlayerName,
      currTee,
      currCourse,
      currScores,
      currPars,
      backNineScores,
      searchLoading,
      isNineHole,
    } = this.state;

    const { closeScoreBuilder, isLoading, defaultScores } = this.props;

    return (
      <Transition.Root show={this.props.scoreBuilderOpen} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={this.props.closeScoreBuilder}
        >
          <div className="fixed inset-0" />
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      {currentMode === MODE.CONFIRM_SELECT && (
                        <ConfirmationPage
                          scores={currScores}
                          pars={currPars}
                          isNineHole={isNineHole}
                          currCourse={currCourse}
                          currDate={currDate}
                          currPlayerName={currPlayerName}
                          closeScoreBuilder={closeScoreBuilder}
                          setPlayerName={(e) =>
                            this.setState({ currPlayerName: e })
                          }
                          setDate={(e) => this.setState({ currDate: e })}
                          saveAndClose={this.saveAndClose}
                          isLoading={isLoading}
                          editFrontNine={() =>
                            this.setState({
                              backNineScores: false,
                              currentMode: MODE.SCORE_SELECT,
                            })
                          }
                          editBackNine={() =>
                            this.setState({
                              backNineScores: true,
                              currentMode: MODE.SCORE_SELECT,
                            })
                          }
                        />
                      )}

                      {currentMode === MODE.TEE_SELECT && (
                        <TeeSelect
                          tees={currCourse.tees}
                          onTeeSelect={this.onTeeSelect}
                          currTee={currTee}
                          currCourse={currCourse}
                          closeScoreBuilder={closeScoreBuilder}
                          backFunction={this.returnToSearch}
                        />
                      )}

                      {currentMode === MODE.SCORE_SELECT && (
                        <ScoreEnterUI
                          defaultScores={defaultScores}
                          pars={currPars}
                          currCourse={currCourse}
                          scores={currScores}
                          backNineScores={backNineScores}
                          isNineHole={isNineHole}
                          switchNine={() =>
                            this.setState({ backNineScores: !backNineScores })
                          }
                          scoresComplete={this.scoresComplete}
                          onScoreChange={this.onScoreChange}
                          onScoreUp={this.onScoreUp}
                          onScoreDown={this.onScoreDown}
                          closeScoreBuilder={closeScoreBuilder}
                          backFunction={this.returnToTeeSelect}
                        />
                      )}

                      {currentMode === MODE.COURSE_SELECT && (
                        <>
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-gray-100 text-green-500 py-3 sm:py-6 px-5 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-lg tracking-tight font-bold leading-6">
                                  <small className="text-gray-600">
                                    Get started
                                  </small>
                                  <br />
                                  Find your course
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="rounded-md  text-green-500 hover:text-yellow-500"
                                    onClick={closeScoreBuilder}
                                  >
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-x-5">
                              <div className="relative">
                                <InstantSearch
                                  searchClient={searchClient}
                                  indexName="Course"
                                  initialUiState={{
                                    indexName: {
                                      query: currSearchQuery,
                                      page: 1,
                                    },
                                  }}
                                >
                                  <SearchBox
                                    queryHook={this.setQuery}
                                    placeholder="Search for courses"
                                    autoFocus={true}
                                    classNames={{
                                      root: "p-3 shadow-sm",
                                      form: "relative",
                                      input:
                                        "h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0",
                                      submitIcon:
                                        "pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400",
                                      resetIcon:
                                        "absolute right-3 top-3.5 h-3 w-3 text-gray-400",
                                    }}
                                  />
                                  {currSearchQuery && (
                                    <div
                                      className={classNames(
                                        "scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800",
                                        searchLoading && "opacity-40"
                                      )}
                                    >
                                      <CustomHits
                                        onSearchItemSelect={this.onSelect}
                                        currCourse={currCourse}
                                      />
                                    </div>
                                  )}
                                </InstantSearch>
                              </div>
                            </div>
                          </div>
                          {currCourse && (
                            <div className="flex flex-wrap justify-center py-4 px-4 border-t gap-3 border-gray-200 text-xs text-gray-700 truncate">
                              <button
                                type="button"
                                onClick={() =>
                                  this.setState({
                                    currentMode: MODE.TEE_SELECT,
                                  })
                                }
                                className="grow mt-1 text-base px-5 py-3 font-semibold leading-6 text-md rounded-md text-cream bg-green-500 hover:bg-green-400"
                              >
                                {isLoading ? (
                                  <Preloader addClasses="-ml-1 mr-3 h-5 w-5 text-yellow-500" />
                                ) : (
                                  <>
                                    {currCourse.name}: Tees
                                    <ChevronRightIcon className="inline h-5 w-5 mr-2" />
                                  </>
                                )}
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function CustomHits(props) {
  const { hits, results, sendEvent } = useHits(props);

  return (
    <>
      {hits.map((hit) => (
        <div
          key={hit.objectID}
          onClick={() => props.onSearchItemSelect(hit)}
          className="cursor-pointer truncate select-none font-bold px-4 py-2 hover:bg-green-600 hover:text-white"
        >
          {props.currCourse && props.currCourse.id == hit.objectID ? (
            <Preloader addClasses="mx-auto my-3 h-5 w-5 text-yellow-500" />
          ) : (
            <>
              <Highlight attribute="name_or_club" hit={hit} />
              <span className="inline-flex ml-1 items-center px-1 rounded text-xxs font-medium bg-gray-100 text-gray-800 leading-4">
                {hit.holes}
              </span>
              <div>
                <span className="text-xs font-normal text-gray-400 truncate">
                  <Highlight attribute="club.city" hit={hit} />,{" "}
                  <Highlight attribute="club.state" hit={hit} />,{" "}
                  <Highlight attribute="club.country" hit={hit} />
                </span>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
}

class PlayerName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formOpen: false,
      text_body: props.currPlayerName || "",
    };
    this.nameSelected = this.nameSelected.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.closeForm = this.closeForm.bind(this);
  }

  nameSelected(e) {
    this.setState({ formOpen: false });
  }

  changeHandler(e) {
    this.setState({ text_body: e.target.value });
  }

  closeForm() {
    this.setState({ formOpen: false });
    this.props.setPlayerName(this.state.text_body);
  }

  render() {
    return (
      <div className="flex-grow">
        <span className="text-xxs leading-3 uppercase block font-bold text-gray-500 mb-1">
          Player name
        </span>
        {this.state.formOpen ? (
          <div className="mt-1 flex rounded-md  space-x-2">
            <div className="relative flex items-stretch flex-grow">
              <input
                type="text"
                name="name"
                id="name"
                value={this.state.text_body}
                onChange={this.changeHandler}
                autoFocus
                className="focus:ring-green-600  focus:border-green-600 py-3 block w-full rounded-md sm:text-sm border-gray-300"
                placeholder="Add player name..."
              />
            </div>
            <button
              type="button"
              onClick={this.closeForm}
              className="flex-initial px-2.5 py-2 rounded-md bg-green-500 text-sm font-bold hover:bg-green-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-green-300 focus:border-green-300"
            >
              <CheckIcon className="h-5 w-5 text-cream" />
            </button>
          </div>
        ) : (
          <button
            className="text-green-500 bg-white flex-auto px-4 py-3 rounded-md font-bold text-left text-sm w-full"
            onClick={() => this.setState({ formOpen: !this.state.formOpen })}
          >
            {this.props.currPlayerName
              ? this.props.currPlayerName
              : "Click to add name"}
            <PencilSquareIcon className="relative -top-[2px] left-1 h-3 w-3 inline" />
          </button>
        )}
      </div>
    );
  }
}

class DatePlayed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickerOpen: false,
    };
    this.dateSelected = this.dateSelected.bind(this);
  }

  dateSelected(e) {
    this.setState({ pickerOpen: false });
    this.props.setDate(e);
  }

  render() {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const disabledDays = [{ from: tomorrow, to: new Date(2122, 1, 1) }];

    const formattedDate = this.props.currDate.toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    return (
      <div>
        <div className=" text-gray-500">
          <span className="text-xxs leading-3 uppercase block font-bold text-gray-500 mb-1">
            Date played
          </span>
          <button
            className="text-green-500 bg-white flex-auto px-4 py-3 rounded-md font-bold text-left text-sm w-full"
            onClick={() =>
              this.setState({ pickerOpen: !this.state.pickerOpen })
            }
          >
            {formattedDate}
            {this.state.pickerOpen ? (
              <ChevronUpIcon className="relative -top-[2px] left-1 h-4 w-4 inline" />
            ) : (
              <ChevronDownIcon className="relative -top-[2px] left-1 h-4 w-4 inline" />
            )}
          </button>
        </div>
        {this.state.pickerOpen && (
          <DayPicker
            mode="single"
            defaultMonth={this.props.currDate}
            disabled={disabledDays}
            selected={this.props.currDate}
            onSelect={this.dateSelected}
          />
        )}
      </div>
    );
  }
}

function ConfirmationPage(props) {
  return (
    <>
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="bg-gray-100 text-green-500 py-3 sm:py-6 px-5 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="text-lg tracking-tight font-bold leading-6">
              <small className="text-gray-600">Here's your round at</small>
              <br />
              {props.currCourse.name}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md  text-green-500 hover:text-yellow-500"
                onClick={props.closeScoreBuilder}
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-x-7 gap-y-4 p-5 pt-2 mb-4 bg-gray-100">
          <DatePlayed currDate={props.currDate} setDate={props.setDate} />
          <PlayerName
            currPlayerName={props.currPlayerName}
            setPlayerName={props.setPlayerName}
          />
        </div>
        <div className="flex flex-col gap-x-5 px-3">
          <table className="table-fixed border">
            <thead className="border-b">
              <tr className="border-b bg-gray-100 text-gray-500">
                {[...Array(9).keys()].map((score, index) => (
                  <td className="text-center text-sm py-2 border-r" key={index}>
                    {index + 1}
                  </td>
                ))}
                <td className="text-center text-xs border-r bg-green-500">
                  <button
                    className="text-yellow-500 hover:text-yellow-300 w-full h-full py-2.5 bg-green-500 font-bold cursor-pointer"
                    onClick={props.editFrontNine}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                {[...Array(9).keys()].map((par, index) => (
                  <td
                    className="text-center text-sm py-2 border-r text-gray-400"
                    key={index}
                  >
                    {props.pars[index]}
                  </td>
                ))}
                <td className="text-center text-sm py-2 text-gray-400 w-12">
                  {props.pars.slice(0, 9).reduce((a, b) => a + b, 0)}
                </td>
              </tr>
              <tr className="border-b">
                {[...Array(9).keys()].map((score, index) => (
                  <td
                    className="text-center text-sm py-2 border-r font-bold"
                    key={index}
                  >
                    {props.scores[index] ? (
                      <span
                        className={scoreClass(
                          props.pars.slice(0,9),
                          props.scores.slice(0,9),
                          index
                        )}
                      >
                        {props.scores[index]}
                      </span>
                    ) : (
                      "–"
                    )}
                  </td>
                ))}
                <td className="text-center text-sm py-2 font-bold w-12">
                  {props.scores.slice(0, 9).reduce((a, b) => a + b, 0)}
                </td>
              </tr>
            </tbody>
          </table>
          {!props.isNineHole && (
            <>
              <table className="table-fixed border mt-4">
                <thead className="border-b">
                  <tr className="border-b bg-gray-100 text-gray-500">
                    {[...Array(9).keys()].map((score, index) => (
                      <td
                        className="text-center text-sm py-2 border-r"
                        key={index}
                      >
                        {index + 10}
                      </td>
                    ))}
                    <td className="text-center text-xs border-r bg-green-500">
                      <button
                        className="text-yellow-500 hover:text-yellow-300 w-full h-full py-2.5 bg-green-500 font-bold cursor-pointer"
                        onClick={props.editBackNine}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    {props.pars.slice(9, 18).map((par, index) => (
                      <td
                        className="text-center text-sm py-2 border-r text-gray-400"
                        key={index}
                      >
                        {par}
                      </td>
                    ))}
                    <td className="text-center text-sm py-2 text-gray-400 w-12">
                      {props.pars.slice(9, 18).reduce((a, b) => a + b, 0)}
                    </td>
                  </tr>
                  <tr className="border-b">
                    {[...Array(9).keys()].map((score, index) => (
                      <td
                        className="text-center text-sm py-2 border-r font-bold"
                        key={index}
                      >
                        {props.scores[index + 9] ? (
                          <span
                            className={scoreClass(
                              props.pars.slice(9, 18),
                              props.scores.slice(9, 18),
                              index
                            )}
                          >
                            {props.scores[index + 9]}
                          </span>
                        ) : (
                          "–"
                        )}
                      </td>
                    ))}
                    <td className="text-center text-sm py-2 font-bold w-12">
                      {props.scores.slice(9, 18).reduce((a, b) => a + b, 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table-fixed border w-1/2 mt-4">
                <thead className="border-b">
                  <tr className="border-b bg-gray-100 text-gray-500">
                    <td className="text-center text-sm py-2 border-r">In</td>
                    <td className="text-center text-sm py-2 border-r">Out</td>
                    <td className="text-center text-sm py-2 border-r">Total</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="text-center text-sm py-2 w-12 font-bold border-r">
                      {props.scores.slice(0, 9).reduce((a, b) => a + b, 0)}
                    </td>
                    <td className="text-center text-sm py-2 w-12 font-bold border-r">
                      {props.scores.slice(9, 18).reduce((a, b) => a + b, 0)}
                    </td>
                    <td className="text-center text-sm py-2 w-12 font-bold border-r">
                      {props.scores.reduce((a, b) => a + b, 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-wrap justify-center py-4 px-4 border-t gap-3 border-gray-200 text-xs text-gray-700">
        {props.isNineHole ? (
          <button
            type="button"
            onClick={props.editFrontNine}
            className="items-center mt-1 px-5 py-3 font-semibold leading-6 text-sm rounded-md text-green-500 bg-gray-100 hover:bg-gray-300"
          >
            <ChevronLeftIcon className="inline mr-1 h-5 w-5" /> Scores
          </button>
        ) : (
          <button
            type="button"
            onClick={props.editBackNine}
            className="items-center mt-1 px-5 py-3 font-semibold leading-6 text-sm rounded-md text-green-500 bg-gray-100 hover:bg-gray-300"
          >
            <ChevronLeftIcon className="inline mr-1 h-5 w-5" /> Back 9
          </button>
        )}

        <button
          type="button"
          onClick={props.saveAndClose}
          className="grow mt-1 text-base px-5 py-3 font-semibold leading-6 text-md rounded-md text-yellow-500 bg-green-500 hover:bg-green-400"
        >
          {props.isLoading ? (
            <Preloader addClasses="inline -ml-1 mr-3 h-5 w-5 text-yellow-500" />
          ) : (
            <CheckIcon className="inline h-5 w-5 mr-2" />
          )}
          Save and Close
        </button>
      </div>
    </>
  );
}

function scoreClass(pars, scores, index) {
  const net = scores[index] - pars[index];
  switch (true) {
    case net <= -1:
      return "birdie";
      break;
    case net == 0:
      return "par";
      break;
    case net == 1:
      return "bogey";
      break;
    case net > 1:
      return "bogey-worse";
      break;
    default:
      return "par";
      break;
  }
}

function scoreName(pars, scores, index) {
  const net = scores[index] - pars[index];
  switch (true) {
    case scores[index] == 1:
      return "hole in one 😮";
      break;
    case net <= -3:
      return "albatross";
      break;
    case net <= -2:
      return "eagle";
      break;
    case net <= -1:
      return "birdie";
      break;
    case net == 0:
      return "par";
      break;
    case net == 1:
      return "bogey";
      break;
    default:
      return `+${net} Bogey`;
      break;
  }
}

function ScoreEnterUI(props) {
  return (
    <>
      
      {props.backNineScores && !props.isNineHole ? (
        <>
          <div className="h-0 flex-1 overflow-y-auto">
            <div className="bg-gray-100 text-green-500 py-3 sm:py-6 px-5 sm:px-6">
              <div className="flex items-center justify-between">
                <Dialog.Title className="text-lg tracking-tight font-bold leading-6">
                  <small className="text-gray-600">Enter scores for</small>
                  <br />
                  Holes 10-18
                </Dialog.Title>
                <div className="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    className="rounded-md  text-green-500 hover:text-yellow-500"
                    onClick={props.closeScoreBuilder}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-x-5 px-5">
              <div className="mt-5">
                {props.pars.slice(9, 18).map((par, index) => (
                  <ScoreEnterItem
                    {...props}
                    score={props.scores[index + 9]}
                    index={index + 9}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center py-4 px-4 border-t gap-3 border-gray-200 text-xs text-gray-700">
            <button
              type="button"
              onClick={props.switchNine}
              className="items-center mt-1 px-5 py-3 font-semibold leading-6 text-sm rounded-md text-green-500 bg-gray-100 hover:bg-gray-300"
            >
              <ChevronLeftIcon className="inline mr-1 h-5 w-5" /> Front 9
            </button>
            <button
              type="button"
              onClick={props.scoresComplete}
              className="grow items-center mt-1 text-base px-5 py-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-green-500 hover:bg-green-400"
            >
              Done
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="h-0 flex-1 overflow-y-auto">
            <div className="bg-gray-100 text-green-500 py-3 sm:py-6 px-5 sm:px-6">
              <div className="flex items-center justify-between">
                <Dialog.Title className="text-lg tracking-tight font-bold leading-6">
                  <small className="text-gray-600">Enter scores for</small>
                  <br />
                  Holes 1-9
                </Dialog.Title>
                <div className="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    className="rounded-md  text-green-500 hover:text-yellow-500"
                    onClick={props.closeScoreBuilder}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            { props.defaultScores && (
                <div className="flex flex-wrap justify-center py-2 px-2 bg-[#4d4d4d] text-xs text-cream">Look familiar? These are your scores so check 'em.</div>
              )
            }
            <div className="flex flex-col gap-x-5 px-5">
              <div className="mt-5">
                {props.pars.slice(0, 9).map((par, index) => (
                  <ScoreEnterItem
                    {...props}
                    score={props.scores[index]}
                    index={index}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center py-4 px-4 border-t gap-3 border-gray-200 text-xs text-gray-700">
            <button
              type="button"
              onClick={props.backFunction}
              className="items-center mt-1 px-5 py-3 font-semibold leading-6 text-sm rounded-md text-green-500 bg-gray-100 hover:bg-gray-300"
            >
              <ChevronLeftIcon className="inline mr-1 h-5 w-5" /> Tees
            </button>
            {props.isNineHole ? (
              <button
                type="button"
                onClick={props.scoresComplete}
                className="grow items-center mt-1 text-base px-5 py-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-green-500 hover:bg-green-400"
              >
                Done
              </button>
            ) : (
              <button
                type="button"
                onClick={props.switchNine}
                className="grow items-center mt-1 text-base px-5 py-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-green-500 hover:bg-green-400"
              >
                Next: Holes 10-18
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
}

function ScoreEnterItem(props) {
  const { index, scores, score, pars } = props;

  return (
    <div className="flex rounded-md shadow-sm mb-3">
      <span className="-ml-px relative items-center space-x-2 px-3 py-[7px]  rounded-l-md border border-gray-300 bg-white text-gray-400 text-xs text-right">
        <span className="font-bold text-xs text-gray-900">
          Hole {index + 1}
        </span>
        <br />
        Par {props.pars[index]}
      </span>
      <div className="relative flex items-stretch flex-grow border-t border-b border-gray-300">
        <div className="flex flex-col justify-between justify-items-end w-full score-entry-label">
          <div className="grow block border-l-0 w-full font-bold text-xl border-gray-300 text-center pt-1 px-3">
            {score}
          </div>
          <span
            className={classNames(
              scoreClass(pars, scores, index),
              "block w-full text-center text-[8px] font-bold uppercase bg-gray-100 text-gray-800"
            )}
          >
            {score ? scoreName(pars, scores, index) : `Enter score →`}
          </span>
        </div>
      </div>
      <button
        type="button"
        onClick={(e) => props.onScoreDown(index)}
        className="-ml-px relative inline-flex items-center space-x-2 px-3 py-[7px] border border-gray-300 text-sm font-bold bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
      >
        <MinusIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
      </button>
      <button
        type="button"
        onClick={(e) => props.onScoreUp(index)}
        className="-ml-px relative inline-flex items-center space-x-2 px-3 py-[7px] border border-gray-300 text-sm font-bold rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
      >
        <PlusIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
      </button>
    </div>
  );
}

function TeeSelect(props) {
  return (
    <>
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="bg-gray-100 text-green-500 py-3 sm:py-6 px-5 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="text-lg tracking-tight font-bold leading-6">
              <small className="text-gray-600">{props.currCourse.name}</small>
              <br />
              Select a tee
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md  text-green-500 hover:text-yellow-500"
                onClick={props.closeScoreBuilder}
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-x-5 px-5">
          <RadioGroup value={props.currTee} onChange={props.onTeeSelect}>
            <div className="mt-4 grid grid-cols-1 gap-y-4 sm:grid-cols-3 sm:gap-x-4">
              {props.tees.map((tee) => (
                <RadioGroup.Option
                  key={tee.id}
                  value={tee}
                  className={({ checked, active }) =>
                    classNames(
                      checked ? "border-transparent" : "border-gray-300",
                      active ? "border-green-500 ring-2 ring-green-500" : "",
                      "relative bg-white border rounded-lg shadow-sm p-3 flex cursor-pointer focus:outline-none"
                    )
                  }
                >
                  {({ checked, active }) => (
                    <>
                      <span className="flex-1 flex">
                        <span className="flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className="block text-sm font-bold text-gray-900"
                          >
                            {tee.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className="mt-1 flex items-center text-sm text-gray-500"
                          >
                            {tee.colour} {tee.total_distance}m
                          </RadioGroup.Description>
                        </span>
                      </span>
                      <CheckCircleIcon
                        className={classNames(
                          !checked ? "invisible" : "",
                          "h-5 w-5 text-green-600"
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          active ? "border" : "border-2",
                          checked ? "border-green-500" : "border-transparent",
                          "absolute -inset-px rounded-lg pointer-events-none"
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>
      <div className="flex flex-wrap justify-center py-4 px-4 border-t gap-3 border-gray-200 text-xs text-gray-700">
        <button
          type="button"
          onClick={props.backFunction}
          className="items-center mt-1 px-5 py-3 font-semibold leading-6 text-sm rounded-md text-green-500 bg-gray-100 hover:bg-gray-300"
        >
          <ChevronLeftIcon className="inline mr-1 h-5 w-5" /> Search
        </button>
        <button
          type="button"
          className="grow items-center mt-1 px-5 py-3 font-semibold leading-6 text-sm rounded-md cursor-not-allowed text-gray-400 bg-gray-100"
        >
          Select a tee
        </button>
      </div>
    </>
  );
}


export default ScoreCardBuilder;
