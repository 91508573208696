import React from "react";
import { Fragment } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import axios from "axios";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import { createTheme, useTheme } from "@mui/material/styles";
import { Transition, Dialog } from "@headlessui/react";
import Uploader from "../uploads/Uploader";
import Preloader from "../global/Preloader";
import {
  CameraIcon,
  XMarkIcon,
  SparklesIcon,
  FilmIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  PauseIcon,
  PlayIcon,
} from "@heroicons/react/20/solid";

class UploadDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cropMode: false,
      upload: this.props.upload,
      isMediaLoading: true,
    };
    this.loadCropUi = this.loadCropUi.bind(this);
    this.onCropSubmit = this.onCropSubmit.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onTrimChange = this.onTrimChange.bind(this);
  }

  componentDidMount() {
    this.props.passClearStateFunc(() =>
      this.setState({ upload: null, cropMode: false })
    );
  }

  loadCropUi(upload) {
    this.setState({ cropMode: true, upload: upload });
  }

  onCropSubmit() {
    const { upload, croppedArea, trimRange } = this.state;

    let cw = parseInt((croppedArea.width / 100) * upload.width);
    let ch = parseInt((croppedArea.height / 100) * upload.height);
    let cx = parseInt((croppedArea.x / 100) * upload.width);
    let cy = parseInt((croppedArea.y / 100) * upload.height);

    let image = {
      id: this.state.upload.id,
      cw: cw,
      ch: ch,
      cx: cx,
      cy: cy,
    };

    if (upload.resource_type == "video") {
      image = { ...image, 
        start_time: trimRange[0],
        end_time: trimRange[1]
      }
    }
    
    this.props.setLoading(true);

    axios.patch(this.props.crop_upload_path, { image: image }).then((res) => {
      console.log(res.data);
      analytics.track("File Cropped" )
      this.setState({ cropMode: false, upload: res.data.upload }, () =>
        this.props.uploadSuccess(this.state.upload)
      );
    });
  }

  onCropChange(croppedArea) {
    this.setState({ croppedArea: croppedArea });
  }

  onTrimChange(vals) {
    this.setState({ trimRange: vals });
  }

  render() {
    const {
      crop_ratio,
      uploads_path,
      uploadDialogOpen,
      closeUploadDialog,
      userUploaded,
      isLoading,
      preloader_icon
    } = this.props;
    const { cropMode, upload, isMediaLoading } = this.state;

    return (
      <Transition.Root show={uploadDialogOpen} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => closeUploadDialog()}
        >
          <div className="fixed inset-0" />
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-gray-100 text-green-500 py-6 px-5 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-xl tracking-tight font-bold ">
                              {cropMode || upload
                                ? "Adjust Crop Region"
                                : "Upload a file"}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md  text-green-500 hover:text-yellow-500"
                                onClick={closeUploadDialog}
                              >
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6 relative">
                            <div className="space-y-6 pt-6 pb-5">
                              {cropMode || upload ? (
                                <CropUI
                                  upload={upload}
                                  crop_ratio={crop_ratio}
                                  isMediaLoading={isMediaLoading}
                                  onCropComplete={this.onCropChange}
                                  onTrim={this.onTrimChange}
                                  mediaLoaded={() =>
                                    this.setState({ isMediaLoading: false })
                                  }
                                />
                              ) : (
                                <div>
                                  <div className="mt-3 text-center sm:mt-5">
                                    <div className="flex flex-row">
                                      <div className="flex-1 px-2">
                                        <div className="flex inline-flex  items-center justify-center text-green-500">
                                          <CameraIcon
                                            className="h-10 w-10"
                                            aria-hidden="true"
                                          />
                                        </div>
                                        <p className="font-bold text-2xl tracking-tight">
                                          Photo
                                        </p>
                                        <dd className="text-sm text-gray-500">
                                          Most image formats accepted. Max file
                                          size 100MB.
                                        </dd>
                                      </div>

                                      <div className="flex-1 px-2">
                                        <div className="flex inline-flex  items-center justify-center text-green-500">
                                          <FilmIcon
                                            className="h-10 w-10"
                                            aria-hidden="true"
                                          />
                                        </div>
                                        <p className="font-bold text-2xl tracking-tight">
                                          Video
                                        </p>
                                        <dd className="text-sm text-gray-500">
                                          Maximum 10 seconds. Longer videos will
                                          be automatically trimmed.{" "}
                                        </dd>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="rounded-md bg-green-50 p-4 mt-6 mb-4">
                                    <div className="flex">
                                      <div className="flex-shrink-0">
                                        <SparklesIcon
                                          className="h-5 w-5 text-green-400"
                                          aria-hidden="true"
                                        />
                                      </div>
                                      <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="text-sm text-green-700">
                                          PRO accounts coming soon with unlocked
                                          features and watermark removal.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mt-5"></div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-center">
                        {cropMode || upload ? (
                          <div className="p-4 w-full">
                            {this.state.isMediaLoading ? (
                              <button
                                type="button"
                                className="items-center block w-full px-9 py-3 font-semibold leading-6 rounded-md cursor-not-allowed text-gray-500 bg-gray-100 hover:bg-gray-100 transition ease-in-out duration-150"
                              >
                                <Preloader addClasses="-ml-1 mr-3 h-5 w-5 text-gray-500 inline" />
                                Loading {upload.resource_type} preview
                              </button>
                            ) : (
                              <button
                                onClick={this.onCropSubmit}
                                className="items-center block w-full px-9 py-3 font-semibold leading-6 rounded-md text-white bg-green-500 hover:bg-green-400"
                                type="button"
                              >
                                {isLoading ? (
                                  <React.Fragment>
                                    <Preloader addClasses="inline -ml-1 mr-3 h-6 w-6 text-cream" />
                                    Saving...
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>Crop & Save</React.Fragment>
                                )}
                              </button>
                            )}
                          </div>
                        ) : (
                          <Uploader
                            crop_ratio={crop_ratio}
                            uploads_path={uploads_path}
                            uploadSuccess={this.loadCropUi}
                            upload={upload}
                            userUploaded={userUploaded}
                            preloader_icon={preloader_icon}
                          />
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

// https://codesandbox.io/s/react-easy-crop-forked-6rtj6b?file=/src/index.js:366-374
// https://kitchen.vibbio.com/blog/optimizing-html5-video-scrubbing/

class CropUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: { x: 0, y: 0 },
      zoom: 1,
      videoRef: React.createRef(),
      playing: true,
      trimRange: [this.props.upload.start_time, this.props.upload.end_time],
      isSeeking: false,
      targetSeekTime: 0,
      minDistance: 1,
    };
    this.onTrimChange = this.onTrimChange.bind(this);
  }

  componentDidMount() {
    const { upload } = this.props;
    this.props.onTrim(this.state.trimRange);

    if (upload.cw && upload.ch) {
      let cw = (upload.cw / upload.width) * 100;
      let ch = (upload.ch / upload.height) * 100;
      let cx = (upload.cx / upload.width) * 100;
      let cy = (upload.cy / upload.height) * 100;
      this.setState({
        initialCroppedAreaPercentages: { width: cw, height: ch, x: cx, y: cy },
      });
    }
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onTrimChange(event, newValue, activeThumb) {
    const { minDistance, trimRange } = this.state;
    let scrub_to;

    this.setState({ playing: false });
    this.state.videoRef.current.pause();

    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      scrub_to = Math.min(newValue[0], trimRange[1] - minDistance);
      this.setState({ trimRange: [scrub_to, trimRange[1]] });
      this.props.onTrim(trimRange);
    } else {
      scrub_to = Math.max(newValue[1], trimRange[0] + minDistance);
      this.setState({ trimRange: [trimRange[0], scrub_to] });
      this.props.onTrim(trimRange);
    }
    this.state.videoRef.current.currentTime = scrub_to;
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  setVideoRef = (video) => {
    this.setState({ videoRef: video }, () => {
      const vid = this.state.videoRef.current
      vid.playsInline = true
      vid.loop = false
      vid.addEventListener('timeupdate', (event) => {
        if (vid.currentTime >= this.state.trimRange[1] && !vid.paused) {
          vid.currentTime = this.state.trimRange[0]
        }
      })
      vid.addEventListener('ended', (event) => {
        vid.currentTime = this.state.trimRange[0]
        vid.play()
      })
    })
  };

  togglePlaying() {
    this.setState({ playing: !this.state.playing }, () => {
      if (this.state.playing){
        this.state.videoRef.current?.play();
      } else {
        this.state.videoRef.current?.pause();
      }
    });
  }

  render() {
    const { upload, isMediaLoading, crop_ratio, onCropComplete, mediaLoaded } =
      this.props;

    const marks = [
      {
        value: 0,
        label: `${this.state.trimRange[0]}s`,
      },
      {
        value: upload.duration,
        label: `${this.state.trimRange[1]}s`,
      },
    ];

    return (
      <>
        <div className="h-56 sm:h-96">
          <div className="crop-container bg-green-500 absolute top-0 left-0 right-0 bottom-0">
            {isMediaLoading && <PreloaderPanel />}
            <Cropper
              image={upload.resource_type == "image" && upload.uncropped}
              video={upload.resource_type == "video" && upload.uncropped}
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={crop_ratio}
              initialCroppedAreaPercentages={
                this.state.initialCroppedAreaPercentages
              }
              onCropChange={this.onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={this.onZoomChange}
              onMediaLoaded={mediaLoaded}
              setVideoRef={(ref) => {
                upload.resource_type == "video" && this.setVideoRef(ref);
              }}
            />
          </div>
        </div>
        <div className="controls flex-col w-full sm:w-96">
          <div className="my-3 w-full flex">
            <MagnifyingGlassMinusIcon className="h-8 w-8 text-gray-400 mx-4 relative" />
            <Slider
              getAriaLabel={() => "Zoom"}
              value={this.state.zoom}
              min={1}
              max={1.7}
              step={0.05}
              theme={theme}
              onChange={(e) => {
                this.onZoomChange(e.target.value);
              }}
            />
            <MagnifyingGlassPlusIcon className="h-8 w-8 text-gray-400 mx-4 relative" />
          </div>

          {upload.resource_type == "video" && (
            <>
              <div className="my-3 w-full flex">
                <ChevronRightIcon className="h-8 w-8 text-gray-400 mx-4 relative" />
                <Slider
                  getAriaLabel={() => "Minimum distance"}
                  value={this.state.trimRange}
                  min={0}
                  max={this.props.upload.duration}
                  valueLabelFormat={(value) => `${value}s`}
                  marks={marks}
                  step={0.1}
                  onChange={this.onTrimChange}
                  valueLabelDisplay="auto"
                  theme={theme}
                  disableSwap
                />
                <ChevronLeftIcon className="h-8 w-8 text-gray-400 mx-4 relative" />
              </div>

              <button onClick={() => this.togglePlaying()}>
                {this.state.playing ? (
                  <PauseIcon className="h-12 w-12 text-gray-400" />
                ) : (
                  <PlayIcon className="h-12 w-12 text-green-500" />
                )}
              </button>
            </>
          )}
        </div>
      </>
    );
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#035C41",
    },
  },
});

const PreloaderPanel = (props) => (
  <div className="inset-2/4 absolute">
    <svg
      role="status"
      className="w-8 h-8 mr-2 text-green-400 animate-spin fill-cream relative  -left-4 -top-4"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  </div>
);

export default UploadDialog;
