import React from "react";
import { Fragment } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import axios from "axios";
import { Transition, Dialog, Menu } from "@headlessui/react";
import Typewriter from "typewriter-effect";
import {
  XMarkIcon,
  CheckIcon,
  PencilIcon,
  PlusSmallIcon,
  ArrowUpCircleIcon,
  FolderOpenIcon,
  ArrowDownTrayIcon,
  HandRaisedIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  ArrowUpTrayIcon,
  ArrowPathIcon,
  PaintBrushIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import ScoreCardBuilder from "./ScoreCardBuilder";
import UploadDialog from "./UploadDialog";
import GuestDownloadDialog from "./GuestDownloadDialog";
import UserDownloadDialog from "./UserDownloadDialog";
import Preloader from "../global/Preloader";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowUp,
  faHandSparkles,
  faSearch,
  faThumbsUp,
} from "@fortawesome/pro-solid-svg-icons";

class CanvasDesigner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryDrawerOpen: true,
      isLoading: false,
      unsavedChanges: true,
      artwork: props.artwork,
      downloadDialogOpen: false,
      artworkDownloadComplete: false,
      artworkParams: null,
      user: props.user,
      formError: null,
    };

    this.togglePrimaryDrawer = this.togglePrimaryDrawer.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setUnsavedChanges = this.setUnsavedChanges.bind(this);
    this.setArtwork = this.setArtwork.bind(this);
    this.setArtworkParams = this.setArtworkParams.bind(this);
    this.saveGuestAndDownload = this.saveGuestAndDownload.bind(this);
    this.downloadOpen = this.downloadOpen.bind(this);
    this.goToStripeCheckout = this.goToStripeCheckout.bind(this);
    this.sendToDBSave = this.sendToDBSave.bind(this);
  }

  componentDidMount() {
    analytics.track("Template Editor Loaded", { key: this.props.canvas.key });
  }

  togglePrimaryDrawer() {
    this.setState({ primaryDrawerOpen: !this.state.primaryDrawerOpen });
  }

  setLoading(val) {
    this.setState({ isLoading: val });
  }

  setUnsavedChanges(val) {
    this.setState({ unsavedChanges: val });
  }

  setArtwork(artwork) {
    console.log(artwork.url);
    this.setState({ artwork: artwork });
  }

  setArtworkParams(artworkParams) {
    this.setState({ artworkParams: artworkParams });
  }

  downloadOpen() {
    if (this.props.signed_in) {
      this.sendToDBSave();
    } else {
      this.setState({ downloadDialogOpen: true });
    }
    analytics.track("Share Dialog Opened", { key: this.props.canvas.key });
  }

  goToStripeCheckout() {
    const { checkout_path } = this.props;
    const { user, artworkDownload } = this.state;
    this.setLoading(true);
    analytics.track("Checkout Started", { type: "payment" });

    axios.get(artworkDownload.checkout_path).then((res) => {
      console.log(res.data.redirect_to);
      window.location.href = res.data.redirect_to;
    });
  }

  saveGuestAndDownload(user) {
    const { guest_user_path } = this.props;

    this.setLoading(true);
    this.setState({ user: user });

    axios
      .post(guest_user_path, { user: user })
      .then((res) => {
        console.log(res.data);
        analytics.track(res.data.tracking_event, {
          user_id: res.data.user_id,
          email: res.data.email,
        });
        this.setState({ user: res.data }, () => {
          this.sendToDBSave();
        });
      })
      .catch((error) => {
        this.setState({ formError: error.response.data.error });
        this.setLoading(false);
      });
  }

  sendToDBSave() {
    const { save_path, canvas } = this.props;
    const { artworkParams, user } = this.state;

    axios
      .post(save_path, { artwork: { ...artworkParams, user_id: user.user_id } })
      .then((res) => {
        this.setState({
          artworkDownload: res.data.download,
          artworkDownloadComplete: true,
        });
        this.setLoading(false);
        analytics.track("Artwork Saved", {
          key: canvas.key,
          user_id: user.user_id,
        });
        this.props.signed_in && this.setState({ downloadDialogOpen: true });
      });
  }

  render() {
    const {
      isLoading,
      unsavedChanges,
      resource_type,
      primaryDrawerOpen,
      artwork,
      downloadDialogOpen,
      artworkDownloadComplete,
      artworkDownload,
      artworkParams,
      user,
      formError,
    } = this.state;
    const {
      canvas,
      preloader_icon,
      registration_path,
      signed_in,
      thumbs_up_icon,
    } = this.props;

    return (
      <div className="flex flex-col h-full">
        <div className="flex flex-1 flex-col grow">
          <CanvasResultAsset
            image={artwork.poster_image}
            video={artwork.url}
            resource_type={artwork.resource_type}
            onImageLoad={() => this.setLoading(false)}
            onImageClick={this.togglePrimaryDrawer}
            preloader_icon={preloader_icon}
            isLoading={isLoading}
            unsavedChanges={unsavedChanges}
            saveLayers={this.sendToDBPreview}
            ratio_class={canvas.ratio_class}
          />
        </div>
        <PrimaryDrawer
          {...this.props}
          setLoading={this.setLoading}
          setUnsavedChanges={this.setUnsavedChanges}
          unsavedChanges={unsavedChanges}
          isLoading={isLoading}
          setArtwork={this.setArtwork}
          setArtworkParams={this.setArtworkParams}
          downloadOpen={this.downloadOpen}
          primaryDrawerOpen={!isMobile || primaryDrawerOpen}
          closePrimaryDrawer={() => this.setState({ primaryDrawerOpen: false })}
        />
        {signed_in && artworkDownload ? (
          <UserDownloadDialog
            downloadDialogOpen={downloadDialogOpen}
            user={user}
            signed_in={signed_in}
            isLoading={isLoading}
            setLoading={this.setLoading}
            artworkDownload={artworkDownload}
            thumbs_up_icon={thumbs_up_icon}
            closeDownloadDialog={() =>
              this.setState({ downloadDialogOpen: false })
            }
            afterDialogClose={() =>
              this.setState({ artworkDownloadComplete: false })
            }
          />
        ) : (
          <GuestDownloadDialog
            downloadDialogOpen={downloadDialogOpen}
            registration_path={registration_path}
            artworkDownloadComplete={artworkDownloadComplete}
            user={user}
            formError={formError}
            signed_in={signed_in}
            isLoading={isLoading}
            saveGuestAndDownload={this.saveGuestAndDownload}
            setLoading={this.setLoading}
            artworkDownload={artworkDownload}
            goToStripeCheckout={this.goToStripeCheckout}
            closeDownloadDialog={() =>
              this.setState({ downloadDialogOpen: false })
            }
            afterDialogClose={() =>
              this.setState({ artworkDownloadComplete: false })
            }
          />
        )}

        <div className="sm:hidden flex flex-shrink-0 justify-end gap-3 p-3 bg-green-500">
          {artworkParams && !isLoading && (
            <button
              type="button"
              onClick={this.downloadOpen}
              className="bg-yellow-500 hover:bg-yellow-400 text-green-500 grow items-center text-center text-base px-4 py-2 font-semibold leading-6 rounded-md basis-1/2"
            >
              <ArrowUpTrayIcon className="flex-shrink-0 h-5 w-5 mr-2 inline" />{" "}
              Share
            </button>
          )}

          <button
            type="button"
            onClick={this.togglePrimaryDrawer}
            className="bg-green-600 hover:bg-green-700 text-yellow-500 grow items-center text-center text-base px-4 py-2 font-semibold leading-6 rounded-md basis-1/2"
          >
            <PaintBrushIcon className="w-5 h-5 mx-auto" />
          </button>
        </div>
      </div>
    );
  }
}

class PrimaryDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text_layers: props.text_layers,
      upload: props.upload,
      userUploaded: false,
      scoreBuilderOpen: false,
      uploadDialogOpen: false,
      scoreCard: props.score_card,
      textUpdatedOnce: false,
      user: props.user,
    };
    this.textUpdated = this.textUpdated.bind(this);
    this.saveScores = this.saveScores.bind(this);
    this.prepareForDB = this.prepareForDB.bind(this);
    this.sendToDBPreview = this.sendToDBPreview.bind(this);

    this.uploadSuccess = this.uploadSuccess.bind(this);
    this.validForPreview = this.validForPreview.bind(this);
    this.assignClearScoreBuilderState =
      this.assignClearScoreBuilderState.bind(this);
    this.assignClearUploadState = this.assignClearUploadState.bind(this);
    this.clearScoreCardBuilder = this.clearScoreCardBuilder.bind(this);
    this.removeUpload = this.removeUpload.bind(this);
    this.getOverideTextFields = this.getOverideTextFields.bind(this);
    this.removeOverideTextFields = this.removeOverideTextFields.bind(this);

    this.clearChildState = null;
  }

  componentDidMount() {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
  }

  getOverideTextFields() {
    const { canvas, scoreCard, text_layers } = this.props;

    analytics.track("Overide Text Fields Loaded");

    axios.get(canvas.canvas_overide_fields_path).then((res) => {
      this.setState({
        text_layers: text_layers.concat(res.data.text_layers),
      });
    });
  }

  removeOverideTextFields() {
    const { text_layers } = this.props;

    this.setState({
      text_layers: text_layers,
    });
    this.props.setUnsavedChanges(true);
  }

  saveScores(club, scoreCard) {
    this.props.setUnsavedChanges(true);
    this.props.setLoading(false);
    this.setState(
      {
        scoreBuilderOpen: false,
        scoreCard: scoreCard,
      },
      () => {
        this.validForPreview() && this.sendToDBPreview();
      }
    );
  }

  uploadSuccess(upload) {
    this.props.setUnsavedChanges(true);
    this.props.setLoading(false);
    analytics.track("Upload Saved", { resource_type: upload.resource_type });

    this.setState(
      {
        upload: upload,
        userUploaded: true,
        uploadDialogOpen: false,
      },
      () => {
        this.validForPreview() && this.sendToDBPreview();
      }
    );
  }

  textUpdated(text, i) {
    this.props.setUnsavedChanges(true);
    let ids = [...this.state.text_layers];
    let index = ids.findIndex((el) => el.id == i);
    ids[index].text_body = text;
    this.setState({
      text_layers: ids,
      textUpdatedOnce: true,
    });
  }

  assignClearUploadState(childClearStateFunc) {
    this.clearUploadState = childClearStateFunc;
  }

  removeUpload() {
    this.clearUploadState();
    this.setState({
      userUploaded: false,
      upload: this.props.upload,
    });
  }

  assignClearScoreBuilderState(childClearStateFunc) {
    this.clearChildState = childClearStateFunc;
  }

  clearScoreCardBuilder() {
    this.clearChildState();
    this.setState({ scoreCard: null });
  }

  prepareForDB() {
    const { text_layers, scoreCard, upload, user } = this.state;
    const { score_card_layer_id, canvas } = this.props;

    let text_customs_attributes = text_layers.map((item) => {
      return {
        body: item.text_body,
        text_layer_id: item.id,
        overide_data_text: true,
      };
    });

    let score_card_customs_attributes = [];
    if (score_card_layer_id) {
      score_card_customs_attributes = [
        {
          score_card_layer_id: score_card_layer_id,
        },
      ];
    }

    let artwork = {
      upload_id: upload && upload.id,
      canvas_id: canvas.id,
      user_id: user && user.user_id,
      score_card_id: scoreCard && scoreCard.id,
      score_card_customs_attributes: score_card_customs_attributes,
      text_customs_attributes: text_customs_attributes,
    };

    return artwork;
  }

  sendToDBPreview() {
    this.props.setLoading(true);
    this.props.closePrimaryDrawer();
    let artworkParams = this.prepareForDB();
    this.props.setArtworkParams(artworkParams);

    axios.post("/artwork_preview", { artwork: artworkParams }).then((res) => {
      this.props.setUnsavedChanges(false);
      this.props.setArtwork(res.data.artwork);
      analytics.track("Artwork Previewed", { key: this.props.canvas.key });
    });
  }

  validForPreview() {
    let uploadValid = true;
    if (this.props.canvas.requires_upload) {
      uploadValid = this.state.userUploaded;
    }

    let scoreCardValid = true;
    if (this.props.canvas.requires_score_card) {
      scoreCardValid = this.state.scoreCard != null;
    }

    return uploadValid && scoreCardValid;
  }

  render() {
    const {
      scoreBuilderOpen,
      scoreCard,
      upload,
      userUploaded,
      uploadDialogOpen,
      textUpdatedOnce,
      text_layers,
    } = this.state;
    const {
      save_score_card_path,
      uploads_path,
      canvas,
      crop_ratio,
      crop_upload_path,
      preloader_icon,
      isLoading,
      unsavedChanges,
      closePrimaryDrawer,
      primaryDrawerOpen,
      current_raw_scores,
      current_raw_scores_total,
    } = this.props;

    const overide_text_layers = text_layers.filter(
      (text) => text.data_fill != "free_text"
    );
    const free_text_layers = text_layers.filter(
      (text) => text.data_fill == "free_text"
    );

    return (
      <Transition.Root show={primaryDrawerOpen} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-50 sm:z-auto"
          onClose={() => closePrimaryDrawer()}
        >
          <div className="fixed sm:static inset-0" />

          {isMobile && (
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
            </Transition.Child>
          )}

          <div className="fixed sm:static inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 sm:pt-[76px]">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-white text-green-500 pt-6 px-5 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-2xl text-[1.6rem] tracking-tight font-bold sm:my-3">
                              Let’s make this yours
                            </Dialog.Title>
                            <div className="sm:hidden ml-3 h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md  text-green-500 hover:text-yellow-500 focus:outline-none"
                                onClick={closePrimaryDrawer}
                              >
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 pr-4 pl-6  relative">
                            <div className="space-y-6 pt-4 pb-5">
                              {canvas.requires_score_card && (
                                <>
                                  <div className="bg-gray-100 flex-col border-0 relative block rounded-lg px-5 pr-4 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none">
                                    <span className="flex items-center w-full">
                                      <span className="text-sm flex flex-col w-full">
                                        <div>
                                          <span
                                            className={classNames(
                                              scoreCard
                                                ? "bg-green-500"
                                                : "bg-gray-800",
                                              "h-6 w-6 rounded-full flex items-center justify-center ring-4 ring-white mt-1 absolute -left-3 top-3"
                                            )}
                                          >
                                            {scoreCard ? (
                                              <CheckIcon className="h-5 w-5 text-white" />
                                            ) : (
                                              <span className="h-6 w-6 text-white text-center text-base font-bold">
                                                1
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                        <span
                                          className={classNames(
                                            scoreCard
                                              ? "text-green-500"
                                              : "text-gray-800",
                                            "text-base font-bold mb-2"
                                          )}
                                        >
                                          Scorecard
                                        </span>
                                        {current_raw_scores && !scoreCard && (
                                          <span className="text-gray-500 mb-4 text-xs font-bold">
                                            We've got the scores for your round of {" "}
                                            <span className="inline-flex items-center rounded-full bg-green-500 px-1 py-0.5 text-yellow-500">
                                              {current_raw_scores_total}
                                            </span>
                                            , we just need to select your course & tee.
                                          </span>
                                        )}

                                        <div className="flex space-x-2">
                                          {scoreCard ? (
                                            <React.Fragment>
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.setState({
                                                    scoreBuilderOpen: true,
                                                  })
                                                }
                                                className="text-green-500 bg-white hover:bg-gray-200 flex-auto px-4 py-3 rounded-md font-bold text-left text-sm w-full  focus:outline-none"
                                              >
                                                <span className="font-bold">
                                                  {scoreCard.course.name} (
                                                  {scoreCard.score_total})
                                                </span>
                                              </button>

                                              <Menu
                                                as="div"
                                                className="relative flex-initial text-left"
                                              >
                                                <div className="flex h-full">
                                                  <Menu.Button className="justify-center w-full rounded-md px-3 py-3 bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500">
                                                    <ChevronDownIcon
                                                      className="h-5 w-5"
                                                      aria-hidden="true"
                                                    />
                                                  </Menu.Button>
                                                </div>

                                                <Transition
                                                  as={Fragment}
                                                  enter="transition ease-out duration-100"
                                                  enterFrom="transform opacity-0 scale-95"
                                                  enterTo="transform opacity-100 scale-100"
                                                  leave="transition ease-in duration-75"
                                                  leaveFrom="transform opacity-100 scale-100"
                                                  leaveTo="transform opacity-0 scale-95"
                                                >
                                                  <Menu.Items className="origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                      <Menu.Item>
                                                        {({ active }) => (
                                                          <a
                                                            href="#"
                                                            onClick={() =>
                                                              this.setState({
                                                                scoreBuilderOpen: true,
                                                              })
                                                            }
                                                            className={classNames(
                                                              active
                                                                ? "bg-gray-100 text-gray-900"
                                                                : "text-gray-700",
                                                              "block px-4 py-2 text-sm"
                                                            )}
                                                          >
                                                            Edit Scores
                                                          </a>
                                                        )}
                                                      </Menu.Item>
                                                      <Menu.Item>
                                                        {({ active }) => (
                                                          <a
                                                            href="#"
                                                            onClick={
                                                              this
                                                                .clearScoreCardBuilder
                                                            }
                                                            className={classNames(
                                                              active
                                                                ? "bg-gray-100 text-gray-900"
                                                                : "text-gray-700",
                                                              "block px-4 py-2 text-sm"
                                                            )}
                                                          >
                                                            Remove scorecard
                                                          </a>
                                                        )}
                                                      </Menu.Item>
                                                    </div>
                                                  </Menu.Items>
                                                </Transition>
                                              </Menu>
                                            </React.Fragment>
                                          ) : (
                                            <button
                                              type="button"
                                              onClick={() =>
                                                this.setState({
                                                  scoreBuilderOpen: true,
                                                })
                                              }
                                              className="text-cream bg-green-500 flex-auto px-4 py-3 rounded-md font-bold text-left text-sm w-full"
                                            >
                                              <span>
                                                { current_raw_scores ? (
                                                    <>
                                                      <FontAwesomeIcon icon={faSearch} className="h-3 w-3 mr-2 inline " />{" "}
                                                      Find course & tee
                                                    </>
                                                  ) : (
                                                    <>
                                                      <PlusSmallIcon className="h-5 w-5 inline " />{" "}
                                                      Add scores
                                                    </>
                                                  )
                                                }
                                                
                                              </span>
                                            </button>
                                          )}
                                        </div>
                                      </span>
                                    </span>
                                  </div>
                                </>
                              )}
                              {canvas.requires_upload && (
                                <div className="bg-gray-100 border-0 relative block rounded-lg px-5 pr-4 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none">
                                  <span className="flex items-center w-full">
                                    <span className="text-sm flex flex-col w-full">
                                      <div>
                                        <span
                                          className={classNames(
                                            userUploaded
                                              ? "bg-green-500"
                                              : "bg-gray-800",
                                            "h-6 w-6 rounded-full flex items-center justify-center ring-4 ring-white mt-1 absolute -left-3 top-3"
                                          )}
                                        >
                                          {userUploaded ? (
                                            <CheckIcon className="h-5 w-5 text-white" />
                                          ) : (
                                            <span className="h-6 w-6 text-white  text-base text-center font-bold">
                                              {!canvas.requires_score_card
                                                ? "1"
                                                : "2"}
                                            </span>
                                          )}
                                        </span>
                                      </div>
                                      <span
                                        className={classNames(
                                          userUploaded
                                            ? "text-green-500"
                                            : "text-gray-800",
                                          "text-base font-bold mb-2"
                                        )}
                                      >
                                        Photo or Video
                                      </span>
                                      <div className="flex space-x-2">
                                        {userUploaded ? (
                                          <React.Fragment>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                this.setState({
                                                  uploadDialogOpen: true,
                                                })
                                              }
                                              className="text-green-500 bg-white hover:bg-gray-200 flex-auto px-4 py-3 rounded-md font-bold text-left text-sm w-full"
                                            >
                                              <span>
                                                <span className="font-bold">
                                                  <PencilIcon className="h-4 w-4 inline text-green-500" />{" "}
                                                  {truncateString(
                                                    upload.filename,
                                                    12
                                                  )}
                                                  .{upload.format}
                                                </span>
                                              </span>
                                            </button>
                                            <button
                                              type="button"
                                              onClick={this.removeUpload}
                                              className="flex-initial px-2.5 py-2 rounded-md bg-white text-sm font-bold hover:bg-gray-200 focus:z-10 focus:outline-none focus:ring-1 focus:ring-green-300 focus:border-green-300"
                                            >
                                              <TrashIcon className="h-4 w-4 text-gray-500" />
                                            </button>
                                          </React.Fragment>
                                        ) : (
                                          <button
                                            type="button"
                                            onClick={() =>
                                              this.setState({
                                                uploadDialogOpen: true,
                                              })
                                            }
                                            className="text-cream bg-green-500 flex-auto px-4 py-3 rounded-md font-bold text-left text-sm w-full"
                                          >
                                            <span>
                                              <PlusSmallIcon className="h-5 w-5 inline " />{" "}
                                              Upload file
                                            </span>
                                          </button>
                                        )}
                                      </div>
                                    </span>
                                  </span>
                                </div>
                              )}

                              {overide_text_layers.length == 0 &&
                                canvas.data_text_count > 0 &&
                                scoreCard && (
                                  <p className="text-xs text-gray-400">
                                    <InformationCircleIcon className="h-4 w-4 inline mr-1" />
                                    {canvas.data_text_count} text fields in this
                                    design are populated by your scorecard.{" "}
                                    <a
                                      onClick={this.getOverideTextFields}
                                      className="underline hover:text-green-500 cursor-pointer"
                                    >
                                      Enter text manually
                                    </a>
                                  </p>
                                )}

                              {text_layers.length > 0 && (
                                <div className="bg-gray-100 border-0 relative block rounded-lg px-5 pr-4 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none">
                                  <span className="flex items-center w-full">
                                    <span className="text-sm flex flex-col w-full">
                                      <div>
                                        <span
                                          className={classNames(
                                            textUpdatedOnce
                                              ? "bg-green-500"
                                              : "bg-gray-800",
                                            "h-6 w-6 rounded-full flex items-center justify-center ring-4 ring-white mt-1 absolute -left-3 top-3"
                                          )}
                                        >
                                          {textUpdatedOnce ? (
                                            <CheckIcon className="h-5 w-5 text-white" />
                                          ) : (
                                            <span className="h-6 w-6 text-white  text-base text-center font-bold">
                                              3
                                            </span>
                                          )}
                                        </span>
                                      </div>
                                      <span
                                        className={classNames(
                                          textUpdatedOnce
                                            ? "text-green-500"
                                            : "text-gray-800",
                                          "text-base font-bold mb-2"
                                        )}
                                      >
                                        Customise text
                                      </span>
                                      <div className="flex flex-col">
                                        {free_text_layers.map((text, i) => (
                                          <div key={text.id} className="w-full">
                                            <PersonalisationText
                                              text={text}
                                              textUpdated={this.textUpdated}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                      <div className="flex flex-col">
                                        {overide_text_layers.length > 0 && (
                                          <>
                                            <p className="ml-2 text-xs text-gray-700 font-bold my-2 mt-4">
                                              These fields will overide the
                                              scorecard.
                                              <button
                                                type="button"
                                                onClick={
                                                  this.removeOverideTextFields
                                                }
                                                className="text-gray-400 underline float-right font-normal"
                                              >
                                                Remove
                                              </button>
                                            </p>
                                            {overide_text_layers.map(
                                              (text, i) => (
                                                <div
                                                  key={text.id}
                                                  className="w-full"
                                                >
                                                  <PersonalisationText
                                                    text={text}
                                                    textUpdated={
                                                      this.textUpdated
                                                    }
                                                  />
                                                </div>
                                              )
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </span>
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-center border-t gap-3 border-gray-200 p-4 pb-safe">
                        {this.validForPreview() ? (
                          <>
                            {(unsavedChanges || isLoading) && (
                              <button
                                type="button"
                                onClick={this.sendToDBPreview}
                                className={classNames(
                                  isLoading
                                    ? "cursor-not-allowed text-green-400 bg-gray-100 hover:bg-gray-100"
                                    : "bg-green-500 hover:bg-green-600 text-yellow-500",
                                  "grow items-center text-center  text-base px-4 py-3 font-semibold leading-6 rounded-md basis-1/2"
                                )}
                              >
                                {isLoading ? (
                                  <React.Fragment>
                                    <Preloader addClasses="inline -ml-1 mr-3 h-5 w-5 text-green-400 fill-cream" />
                                    Refreshing
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <ArrowPathIcon className="flex-shrink-0 h-5 w-5 mr-3 inline" />{" "}
                                    Refresh Preview
                                  </React.Fragment>
                                )}
                              </button>
                            )}
                            {!unsavedChanges && !isLoading && (
                              <>
                                <button
                                  type="button"
                                  onClick={this.props.downloadOpen}
                                  className={classNames(
                                    isLoading
                                      ? "cursor-not-allowed text-gray-400 bg-gray-100 hover:bg-gray-100"
                                      : "bg-yellow-500 hover:bg-yellow-400 text-green-500",
                                    "grow items-center text-center text-sm px-4 py-3 font-semibold leading-6 rounded-md basis-1/2"
                                  )}
                                >
                                  <ArrowUpTrayIcon className="flex-shrink-0 h-5 w-5 mr-2 inline" />{" "}
                                  Share
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                          <button
                            type="button"
                            className="w-full items-center text-center text-base px-4 py-3 font-semibold leading-6 rounded-md cursor-not-allowed text-gray-400 bg-gray-100 hover:bg-gray-100"
                          >
                            <HandRaisedIcon className="flex-shrink-0 h-4 w-4 mr-2 inline" />{" "}
                            Complete steps above
                          </button>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>

          <ScoreCardBuilder
            scoreCard={this.state.scoreCard}
            defaultScores={current_raw_scores}
            save_score_card_path={save_score_card_path}
            saveScores={this.saveScores}
            isLoading={isLoading}
            setLoading={this.props.setLoading}
            scoreBuilderOpen={scoreBuilderOpen}
            closeScoreBuilder={() => this.setState({ scoreBuilderOpen: false })}
            passClearStateFunc={this.assignClearScoreBuilderState}
          />

          <UploadDialog
            uploadDialogOpen={uploadDialogOpen}
            uploads_path={uploads_path}
            crop_ratio={crop_ratio}
            crop_upload_path={crop_upload_path}
            closeUploadDialog={() => this.setState({ uploadDialogOpen: false })}
            uploadSuccess={this.uploadSuccess}
            setLoading={this.props.setLoading}
            isLoading={isLoading}
            userUploaded={userUploaded}
            upload={userUploaded && upload}
            preloader_icon={preloader_icon}
            passClearStateFunc={this.assignClearUploadState}
          />
        </Dialog>
      </Transition.Root>
    );
  }
}

class PersonalisationText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.text.id,
      isFocussed: false,
      remaining_char: props.text.max_char - props.text.text_body.length,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  changeHandler(e) {
    const { text, textUpdated } = this.props;
    const { text_body, id } = this.state;

    if (text.max_char >= e.target.value.length) {
      textUpdated(e.target.value, id);
      this.setState({ remaining_char: text.max_char - e.target.value.length });
    }
  }

  handleFocus(event) {
    this.setState({ isFocussed: true });
    event.target.select();
  }

  render() {
    return (
      <div>
        <div className="mb-3">
          <span className="text-xs ml-2 text-gray-400">
            {this.props.text.label}
          </span>
          {this.state.isFocussed && (
            <div className="text-xs ml-2 mt-1 text-gray-400 float-right">
              {this.state.remaining_char} char. remaining
            </div>
          )}

          <div className="mt-1 flex rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <input
                type="text"
                name="name"
                id="name"
                value={this.props.text.text_body}
                onChange={this.changeHandler}
                onFocus={this.handleFocus}
                onBlur={() => this.setState({ isFocussed: false })}
                className="focus:ring-green-600  focus:border-green-600 py-3 block w-full rounded-md sm:text-sm border-gray-300"
                placeholder={this.props.text.placeholder}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const CanvasResultAsset = (props) => (
  <div className="sm:w-[calc(100%_-28rem)] h-full flex  items-center px-3.5 pt-3.5 relative">
    <div
      className={classNames(
        !props.isLoading && "drop-shadow-[0_10px_35px_rgba(0,0,0,0.25)]",
        "max-w-lg mx-auto relative flex"
      )}
    >
      <div className="align-middle object-scale-down  overflow-hidden">
        {props.resource_type == "image" ? (
          <div
            className={classNames(props.ratio_class, "relative")}
            onClick={props.onImageClick}
          >
            <img
              src={props.image}
              className={classNames({ "blur-xs": props.isLoading })}
              onLoad={props.onImageLoad}
            />
            <div className="absolute top-0 left-0 right-0 bottom-0"></div>
          </div>
        ) : (
          <video
            src={props.video}
            className={classNames({ hidden: props.isLoading })}
            controls={true}
            autoPlay={!props.unsavedChanges}
            loop={true}
            controlsList="nodownload"
            onLoadedData={props.onImageLoad}
          />
        )}

        <Transition
          show={props.isLoading}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute w-full h-full top-0 opacity-80 bg-green-800"></div>
          {props.isLoading && (
            <div className="inset-2/4 absolute">
              <div className="relative -top-6">
                <div className="-left-10 -top-7 absolute">
                  <img src={props.preloader_icon} className="max-w-[70px]" />
                </div>
                <div className="text-cream text-center font-bold relative w-72 -left-36 top-10">
                  <div className="uppercase text-xxs tracking-widest opacity-50">
                    Waiting for
                  </div>
                  <div className="tracking-wide leading-5 text-sm">
                    <Typewriter
                      options={{
                        strings: preloaderStrings.sort(
                          () => Math.random() - 0.5
                        ),
                        autoStart: true,
                        delay: 30,
                        deleteSpeed: 5,
                        pauseFor: 2500,
                        loop: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </div>
    </div>
  </div>
);

const preloaderStrings = [
  "Your first drink in the clubhouse...",
  "Your partner’s tedious putting setup...",
  "Your tee time to start...",
  "The drinks cart to arrive...",
  "That gust of wind to pass...",
  "That cheque from LIV to arrive...",
  "Tiger to win another major...",
  "An Augusta visitors pass...",
];

function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "..";
  } else {
    return str;
  }
}

export default CanvasDesigner;
