import React from "react";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { isMobile } from "react-device-detect";

import {
  CheckIcon,
  ArrowDownTrayIcon,
  IdentificationIcon,
  PaperAirplaneIcon,
  ShareIcon,
  SparklesIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

class UserDownloadDialog extends React.Component {
  constructor(props) {
    super(props);
    this.sharePanel = this.sharePanel.bind(this);
    this.shareFile = this.shareFile.bind(this);
  }

  sharePanel() {
    const url = this.props.artworkDownload.url;
    const fileName = this.props.artworkDownload.filename;
    this.props.setLoading(true);

    fetch(url).then(async (response) => {
      const contentType = response.headers.get("content-type");
      const blob = await response.blob();
      const file = new File([blob], fileName, { contentType });
      this.shareFile(file, "Caddisnap", "Check out my creation from Caddisnap");
      this.props.setLoading(false);
    });
  }

  shareFile(file, title, text) {
    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      navigator
        .share({
          files: [file],
          title,
          text,
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  render() {
    const {
      downloadDialogOpen,
      closeDownloadDialog,
      user,
      afterDialogClose,
      isLoading,
      thumbs_up_icon,
      artworkDownload
    } = this.props;


    return (
      <Transition.Root show={downloadDialogOpen} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={closeDownloadDialog}
        >
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="absolute top-0 right-0 pt-4 pr-4 z-20">
              <button
                type="button"
                className=" text-cream hover:text-green-300  focus:ring-0 focus:outline-none"
                onClick={closeDownloadDialog}
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-100"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100"
              afterLeave={() => afterDialogClose()}
            >
              <Dialog.Panel className="h-full px-4 pt-5 pb-4 transform divide-y divide-gray-100 overflow-hidden bg-green-500 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="max-w-xl mx-auto text-center py-12 px-2 sm:px-6 lg:py-10 lg:px-8">
                  <>
                    <img src={thumbs_up_icon} className="w-24 mx-auto" />
                    <h2 className="text-4xl tracking-tight font-bold text-cream sm:block sm:text-4xl">
                      Love your work {user.first_name}!
                    </h2>
                    <p className="text-cream sm:block mt-3">
                      {isMobile
                        ? "You can now share/save your file. Don't be shy."
                        : "You can now download your file."}{" "}
                      We've also sent you an email with the file attached for
                      your convenience.
                    </p>
                    {isMobile ? (
                      <button
                        type="button"
                        onClick={this.sharePanel}
                        className="px-4 py-2.5 inline-block mt-5 font-bold rounded-md text-green-500 bg-yellow-500 hover:bg-yellow-600"
                      >
                        {isLoading ? (
                          <svg
                            className="animate-spin inline -ml-1 mr-3 h-6 w-6 text-green-500 "
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          <ShareIcon className="inline h-5 w-5 mr-2" />
                        )}
                        Share or Save {artworkDownload.resource_type}
                      </button>
                    ) : (
                      <a
                        href={artworkDownload.url}
                        className="px-4 py-2.5 inline-block mt-5 font-bold rounded-md text-green-500 bg-yellow-500 hover:bg-yellow-600"
                      >
                        <ArrowDownTrayIcon className="inline h-5 w-5" /> Download{" "}
                        {artworkDownload.resource_type}
                      </a>
                    )}
                  </>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

export default UserDownloadDialog;
