import React from "react";
import ReactDOM from "react-dom";
import axios from 'axios';
import { Fragment, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { ChevronRightIcon, StarIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      currSearch: '',
      results: []
    }

    this.setQuery = this.setQuery.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }
  componentDidMount() {
    
  }

  getResults(query){
    axios.get(this.props.url + "?search=" + query)
      .then(res => {
        this.setState({results: res.data.clubs})
      }
    )
  }

  setQuery(value){
    this.setState({currSearch: value})
    this.getResults(value)
  } 

  setOpen(value){
    
  } 

  render(){

    const { results } = this.state;

    return(
      <Transition.Root show={this.state.open} as={Fragment} afterLeave={() => this.setQuery('')} appear>
        <Dialog as="div" className="relative z-50" onClose={this.setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox onChange={(person) => (window.location = `clubs/${person.slug}`)}>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                      onChange={(event) => this.setQuery(event.target.value)}
                    />
                  </div>

                  {results.length > 0 && (
                    <Combobox.Options static className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800">
                      {results.map((result) => (
                        <Combobox.Option
                          key={result.id}
                          value={result}
                          className={({ active }) =>
                            classNames('cursor-default select-none px-4 py-2', active && 'bg-green-600 text-white')
                          }
                        >
                          {result.name}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}

                  {!this.state.currSearch == '' && results.length === 0 && (
                    <p className="p-4 text-sm text-gray-500">No clubs found.</p>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
}


