import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import ScoreCardBuilder from '../canvas/ScoreCardBuilder';
import {  PencilIcon } from '@heroicons/react/20/solid'

class EditScoreCard extends React.Component{ 
  constructor(props){
    super(props);
    this.state = {
      scoreCard: props.score_card,
      scoreBuilderOpen: false
    }
    this.saveScores        = this.saveScores.bind(this);
  }

  componentDidMount(){
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
  }

  saveScores(club, scoreCard){
    this.setState({ 
      scoreBuilderOpen: false, 
      scoreCard: scoreCard
    }, () => window.location = this.props.success_path )
  }

  render() {
    const { scoreBuilderOpen, scoreCard } = this.state
    const { save_score_card_path } = this.props

    return (
      <React.Fragment>
        <ScoreCardBuilder 
            scoreCard={scoreCard }
            save_score_card_path={save_score_card_path}
            saveScores={this.saveScores}
            scoreBuilderOpen={scoreBuilderOpen}
            setLoading={() => {}}
            closeScoreBuilder={() => this.setState({scoreBuilderOpen: false})}
            passClearStateFunc={() => {}}
          />
        <button
          type="button"
          onClick={() => this.setState({scoreBuilderOpen: true})}
          className="inline-flex items-center ml-1 px-1.5 py-0.5 border border-gray-300 text-xs leading-5 font-medium rounded text-gray-700 bg-white hover:bg-gray-50"
        >
          <PencilIcon className="h-5 w-3 text-gray-400 group-hover:text-gray-500"/>
        </button>
      </React.Fragment>
    )
  }
}

export default EditScoreCard