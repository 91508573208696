import { useEffect, useMemo, useRef, useState } from 'react'
import * as React from 'react';
import clsx from 'clsx'
import {
  useInView,
} from 'framer-motion/dist/framer-motion'


function Review({ poster_image, poster_type, poster_url, className, ...props }) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = ['0s', '0.1s', '0.2s', '0.3s', '0.4s', '0.5s']
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ]
  }, [])

  return (
    <figure
      className={clsx(
        'animate-fade-in rounded overflow-hidden bg-white opacity-0 drop-shadow-[0_10px_10px_rgba(0,0,0,0.2)]',
        className
      )}
      style={{ animationDelay }}
      {...props}
    >
      { poster_type === 'image' ? (
        <img src={poster_image}/>
      ) : (
        <video
          src={poster_url}
          poster={poster_image}
          controls={false}
          autoPlay
          muted
          playsInline
          loop={true}
        />
      )}
      
    </figure>
  )
}

function splitArray(array, numParts) {
  let result = []
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts
    if (!result[index]) {
      result[index] = []
    }
    result[index].push(array[i])
  }
  return result
}

function ReviewColumn({
  className,
  canvases,
  reviewClassName = () => {},
  msPerPixel = 0,
}) {
  let columnRef = useRef()
  let [columnHeight, setColumnHeight] = useState(0)
  let duration = `${columnHeight * msPerPixel}ms`

  useEffect(() => {
    let resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current.offsetHeight)
    })

    resizeObserver.observe(columnRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div
      ref={columnRef}
      className={clsx('animate-marquee space-y-6 md:space-y-8 py-4', className)}
      style={{ '--marquee-duration': duration }}
    >
      {canvases.concat(canvases).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= canvases.length}
          className={reviewClassName(reviewIndex % canvases.length)}
          {...review}
        />
      ))}
    </div>
  )
}

function ReviewGrid({canvases: canvases}) {
  let containerRef = useRef()
  let isInView = useInView(containerRef, { once: true, amount: 0.4 })
  let columns = splitArray(canvases, 3)
  columns = [columns[0], columns[1], splitArray(columns[2], 2)]

  return (
    <div
      ref={containerRef}
      className="relative -mx-4 grid h-[49rem] max-h-[40vh] grid-cols-2 items-start gap-5 md:gap-8 overflow-hidden px-5 lg:grid-cols-3"
    >
      {isInView && (
        <>
          <ReviewColumn
            canvases={[...columns[0], ...columns[2].flat(), ...columns[1]]}
            reviewClassName={(reviewIndex) =>
              clsx(
                reviewIndex >= columns[0].length + columns[2][0].length &&
                  'md:hidden',
                reviewIndex >= columns[0].length && 'lg:hidden'
              )
            }
            msPerPixel={10}
          />
          <ReviewColumn
            canvases={[...columns[1], ...columns[2][1]]}

            msPerPixel={15}
          />
          <ReviewColumn
            canvases={columns[2].flat()}
            className="hidden lg:block"
            msPerPixel={10}
          />
        </>
      )}
      {/* <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-green-500" /> */}
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-52 bg-gradient-to-t from-green-500" />
    </div>
  )
}

function Carousel(props) {
  return (
    <section
      id="reviews"
      aria-labelledby="reviews-title"
      className=" bg-green-500"
    >
      <div className='mx-auto max-w-4xl px-4 sm:px-6 lg:px-8'>
        <ReviewGrid canvases={props.canvases.sort((a, b) => 0.5 - Math.random())}/>
      </div>
    </section>
  )
}

export default Carousel