import React from "react";
import ReactDOM from "react-dom";

const tabs = [
  { name: 'Social Media', href: '#', current: true },
  { name: 'For Print', href: '#', current: false }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Tabs() {
  return (
    <div>
      <div className="border-gray-200">
        <nav className="-mb-px flex" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current
                  ? 'text-green-600 bg-gray-100'
                  : 'text-gray-500 hover:text-gray-700',
                'w-1/2 py-3 px-1 text-center font-medium text-xs'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  )
}