import React from "react";
import ReactDOM from "react-dom";
import useResizeAware from "react-resize-aware";
import Overlay from "./Overlay";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Masonry from "@mui/lab/Masonry";


class ClubShow extends React.Component {
  constructor(props) {
    super(props);
  	this.state = {
  		resultsLoaded: null,
      modalIsOpen: false,
      loading: false,
      currImage: props.image,
      currClub: props.club, 
      currCanvas: null,
      currPage: 1,
      perPage: 0,
      resultCount: 0,
      isUnsearched: true,
      images: props.images
  	}

  	this.onCanvasSelect = this.onCanvasSelect.bind(this);
    this.onImageSelect  = this.onImageSelect.bind(this);
  	this.closeModal     = this.closeModal.bind(this);
  }
  componentDidMount() {
  }

  onCanvasSelect(canvas){
    this.setState({currCanvas: canvas})
  } 

  onImageSelect(image) {
    this.setState({ modalIsOpen: true, currImage: image });
  }

  closeModal(image) {
    this.setState({ modalIsOpen: false,  currCanvas: null });
  }

  render(){
  	return(
  		<div className="max-w-6xl sm:mx-auto mt-8 sm:px-6 pb-8">
  			{this.state.images.length > 0 && (
  			  <React.Fragment>
            <Masonry columns={{ xs: 2, sm: 3 }} spacing={{ xs: 2, sm: 3, md: 4 }}>
              {this.state.images.map((item, index) => (
                <div
                  onClick={(e) => this.onImageSelect(item)}
                  className="cursor-pointer rounded "
                  key={index}
                >
                  <img src={item.full_size} className="group block w-full" />
                </div>
              ))}
            </Masonry>

  			    <Overlay
  			    	open={this.state.modalIsOpen}
  			    	closeModal={this.closeModal}
              currImage={this.state.currImage}
              currCanvas={this.state.currCanvas}
              onCanvasSelect={this.onCanvasSelect}
  			    />
  			  </React.Fragment>
  			)}
  		</div>
  	)
  }
}

const ImageResultsWrapper = (props) => {
  const [resizeListener, sizes] = useResizeAware();

  return (
    <div className="container mx-auto pt-10" style={{ position: "relative" }}>
      {resizeListener}
      <ImageResults windowW={sizes.width} {...props} />
    </div>
  );
};

const ImageResults = (props) => {
  const divider = props.windowW > 400 ? 300 : 200
  const pR = props.windowW > 0 ? Math.min(Math.round(props.windowW / divider), 4) : 4;
  // const pR = props.windowW > 0 ? Math.min(Math.round(props.windowW / 200, 4) : 3;

  // array of N elements, where N is the number of rows needed
  const rows = [...Array(Math.ceil(props.images.length / pR))];
  // chunk the images into the array of rows
  const imageRows = rows.map((row, idx) =>
    props.images.slice(idx * pR, idx * pR + pR)
  );
  // map the rows as div.row
  const content = imageRows.map((row, idx) => (
    <ImageRow row={row} key={idx} pR={pR} {...props} />
  ));
  return content;
};

const ImageRow = (props) => {
  let allRatios = props.row.map((image) => image.width / image.height);
  let sum = allRatios.reduce((pv, cv) => pv + cv, 0);
  let targetWidth =
    props.pR === allRatios.length
      ? props.windowW
      : props.windowW * (allRatios.length / props.pR);

  return (
    <div className="flex flex-row">
      {props.row.map((image, idx) => (
        <ImageResult
          rowRatio={sum}
          image={image}
          targetWidth={targetWidth}
          key={idx}
          {...props}
        />
      ))}
    </div>
  );
};

const ImageResult = (props) => {
  let ratio = props.image.width / props.image.height;
  let width =
    Math.floor(props.targetWidth * (ratio / props.rowRatio) * 100) / 100;
  let height = width / ratio;

  return (
    <div
      className="p-1 sm:p-2 relative transition duration-300 hover:brightness-50"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <span
        className=""
        onClick={props.onImageSelect.bind(this, props.image)}
      >
        <LazyLoadImage src={props.image.thumbnail} className="w-full h-full"/>
      </span>
    </div>
  );
};

export default ClubShow