import { useState } from 'react'
import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'

export function NavLinks() {
  let [hoveredIndex, setHoveredIndex] = useState(null)

  return [
    ['Templates', '/templates']
  ].map(([label, href], index) => (
    <a
      key={label}
      href={href}
      className="relative -my-2 -mx-3 rounded px-3 py-2 text-sm font-bold text-cream transition-colors delay-150 hover:text-cream hover:delay-[0ms]"
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <AnimatePresence>
        {hoveredIndex === index && (
          <motion.span
            className="absolute inset-0 rounded bg-green-700"
            layoutId="hoverBackground"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{
              opacity: 0,
              transition: { duration: 0.15, delay: 0.2 },
            }}
          />
        )}
      </AnimatePresence>
      <span className="relative z-10">{label}</span>
    </a>
  ))
}
