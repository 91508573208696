import React from "react";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { isMobile } from "react-device-detect";

import {
  CheckIcon,
  ArrowDownTrayIcon,
  IdentificationIcon,
  PaperAirplaneIcon,
  ShareIcon,
  SparklesIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import Preloader from "../global/Preloader";

class DownloadDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accepts_marketing: true,
      email: "",
    };
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.sharePanel = this.sharePanel.bind(this);
    this.shareFile = this.shareFile.bind(this);
  }

  onFormChange(change) {
    this.setState(change);
  }

  onSubmitForm(e) {
    e.preventDefault();

    const { email, accepts_marketing } = this.state;
    const user = { email: email, accepts_marketing: accepts_marketing };

    this.props.saveGuestAndDownload(user);
  }

  sharePanel() {
    const url = this.props.artworkDownload.url;
    const fileName = this.props.artworkDownload.filename;

    fetch(url).then(async (response) => {
      const contentType = response.headers.get("content-type");
      const blob = await response.blob();
      const file = new File([blob], fileName, { contentType });
      this.shareFile(file, "Caddisnap", "Check out my creation from Caddisnap");
    });
  }

  shareFile(file, title, text) {
    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      navigator
        .share({
          files: [file],
          title,
          text,
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  render() {
    const {
      downloadDialogOpen,
      closeDownloadDialog,
      artworkDownloadComplete,
      registration_path,
      user,
      afterDialogClose,
      isLoading,
    } = this.props;
    const { cropMode, upload } = this.state;

    return (
      <Transition.Root show={downloadDialogOpen} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={closeDownloadDialog}
        >


          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="absolute top-0 right-0 pt-4 pr-4 z-20">
              <button
                type="button"
                className=" text-cream hover:text-green-300  focus:ring-0 focus:outline-none"
                onClick={closeDownloadDialog}
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-100"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100"
              afterLeave={() => afterDialogClose()}
            >
              <Dialog.Panel className="h-full px-4 pt-5 pb-4 transform divide-y divide-gray-100 overflow-hidden bg-green-500 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="max-w-xl mx-auto py-12 px-2 sm:px-6 lg:py-10 lg:px-8">
                  {artworkDownloadComplete ? (
                    <>
                      <h2 className="text-4xl tracking-tight font-bold text-cream sm:block sm:text-4xl">
                        Well played!
                      </h2>
                      <p className="text-cream sm:block mt-1">
                        { isMobile ? "You can now share/save your file. Don't be shy." : "You can now download your file."}{" "}
                        We've also sent you an email with the file attached for your convenience.
                      </p>
                      {isMobile ? (
                        <button
                          type="button"
                          onClick={this.sharePanel}
                          className="px-4 py-2.5 inline-block mt-5 font-bold rounded-md text-green-500 bg-yellow-500 hover:bg-yellow-600"
                        >
                          <ShareIcon className="inline h-5 w-5" /> Share/Save{" "}
                          {this.props.artworkDownload.resource_type}
                        </button>
                      ) : (
                        <a
                          href={this.props.artworkDownload.url}
                          className="px-4 py-2.5 inline-block mt-5 font-bold rounded-md text-green-500 bg-yellow-500 hover:bg-yellow-600"
                        >
                          <ArrowDownTrayIcon className="inline h-5 w-5" /> Download{" "}
                          {this.props.artworkDownload.resource_type}
                        </a>
                      )}

                      <div className="rounded-md bg-green-600 p-4 mt-10 mb-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <IdentificationIcon
                              className="h-6 w-6 text-yellow-500"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-lg font-bold text-yellow-500">
                              Save your creations to a Caddisnap account
                            </p>
                          </div>
                        </div>
                        <p className="text-sm text-green-200 mt-4">
                          <CheckIcon className="inline h-5 w-5" /> Download
                          artwork directly from the website.
                          <br />
                          <CheckIcon className="inline h-5 w-5" /> Save your
                          work to come back to later.
                        </p>
                        <a
                          href={`${registration_path}?email=${user.email}&accepts_marketing=${user.accepts_marketing}`}
                          className="px-4 py-2.5 inline-block mt-5 text-base font-bold rounded-md text-cream bg-green-500 hover:bg-green-400"
                        >
                          Create an account
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <h2 className="text-4xl tracking-tight font-bold text-cream sm:block sm:text-4xl">
                        Last step...
                      </h2>
                      <p className="text-cream sm:block mt-1">
                        Enter your email to download your artwork. 
                      </p>
                      <form>
                        <div className="mt-5 w-full">
                          <label htmlFor="email-address" className="sr-only">
                            Email address
                          </label>
                          <input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={this.state.email}
                            onChange={(e) =>
                              this.onFormChange({ email: e.target.value })
                            }
                            autoFocus={true}
                            required
                            className="w-full px-5 py-3 placeholder-gray-500 focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Enter your email"
                          />
                          <div className="flex items-center mt-3">
                            <input
                              id="remember-me"
                              name="remember-me"
                              type="checkbox"
                              checked={this.state.accepts_marketing}
                              onChange={(e) =>
                                this.onFormChange({
                                  accepts_marketing: e.target.checked,
                                })
                              }
                              className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                            />
                            <label
                              htmlFor="remember-me"
                              className="ml-2 block text-sm text-green-200"
                            >
                              Keep me in the loop with Caddisnap updates.
                            </label>
                          </div>
                          <div className="mt-5 rounded-md">
                            <button
                              type="submit"
                              onClick={this.onSubmitForm}
                              className="items-center justify-center px-5 py-3 w-full sm:w-auto border border-transparent text-base font-bold rounded-md text-green-500 bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            >
                              {isLoading ? (
                                <React.Fragment>
                                  <Preloader addClasses="inline -ml-1 mr-3 h-5 w-5 text-green-400 fill-cream" />
                                  Sending it
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <PaperAirplaneIcon className="h-5 w-5 inline mr-2 rotate-90" />{" "}
                                  Send it!
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="rounded-md bg-green-600 p-4 mt-10 mb-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <SparklesIcon
                              className="h-5 w-5 text-green-200"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-green-200">
                              PRO accounts coming soon with unlocked features
                              and watermark removal.
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

export default DownloadDialog;
