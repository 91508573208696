import { forwardRef } from 'react'
import * as React from 'react';
import clsx from 'clsx'

const baseStyles = {
  solid:
    'inline-flex justify-center rounded py-2 px-4 text-sm font-bold outline-2 outline-offset-2 transition-colors',
  outline:
    'inline-flex justify-center rounded border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors',
}

const variantStyles = {
  solid: {
    cyan: 'relative overflow-hidden bg-cyan-500 text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-cyan-600 active:text-white/80 before:transition-colors',
    white:
      'bg-white text-cyan-900 hover:bg-white/90 active:bg-white/90 active:text-cyan-900/70',
    green: 'bg-green-500 text-cream hover:bg-green-700 active:bg-green-800 active:text-cream',
    darkGreen: 'bg-green-600 text-cream hover:bg-green-700 active:bg-green-800 active:text-cream',
  },
  outline: {
    gray: 'border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80',
  },
}

export const Button = forwardRef(function Button(
  { variant = 'solid', color = 'green', className, href, ...props },
  ref
) {
  className = clsx(
    baseStyles[variant],
    variantStyles[variant][color],
    className
  )

  return href ? (
    <a ref={ref} href={href} className={className} {...props} />
  ) : (
    <button ref={ref} className={className} {...props} />
  )
})
