import React from "react";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectanglePro,
  faGolfFlagHole,
  faArrowRight,
  faHighDefinition,
  faHandWave,
} from "@fortawesome/pro-solid-svg-icons";

import {
  CheckIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
  ChevronDownIcon,
  ArrowUpTrayIcon,
  ShoppingBagIcon,
  ClockIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/20/solid";
import Preloader from "../global/Preloader";

class GuestDownloadDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accepts_marketing: true,
      email: "",
    };
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.sharePanel = this.sharePanel.bind(this);
    this.shareFile = this.shareFile.bind(this);
    this.redirectToFile = this.redirectToFile.bind(this);
  }

  onFormChange(change) {
    this.setState(change);
  }

  onSubmitForm(e) {
    e.preventDefault();

    const { email, accepts_marketing } = this.state;
    const user = { email: email, accepts_marketing: accepts_marketing };

    this.props.saveGuestAndDownload(user);
  }

  sharePanel() {
    const url = this.props.artworkDownload.url;
    const fileName = this.props.artworkDownload.filename;
    this.props.setLoading(true);
    analytics.track("Web Share Initiated")

    fetch(url).then(async (response) => {
      const contentType = response.headers.get("content-type");
      const blob = await response.blob();
      const file = new File([blob], fileName, { contentType });
      this.shareFile(
        file,
        "Caddisnap",
        "Check out my creation from @Caddisnap"
      );
      this.props.setLoading(false);
    });
  }

  shareFile(file, title, text) {
    const { artworkDownload } = this.props;
    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      navigator
        .share({
          files: [file],
          title,
          text,
        })
        .then(() =>
          analytics.track("Artwork Shared", {
            mobile: true,
            web_share_dialog: true,
            key: artworkDownload.canvas_key,
          })
        )
        .catch((error) =>
          analytics.track("Web Share Cancelled", { error: error })
        );
    } else {
      analytics.track("Web Share Failed", {
        error: "No support for share dialog",
      });
      this.redirectToFile();
    }
  }

  redirectToFile() {
    const { artworkDownload } = this.props;
    analytics.track("Artwork Shared", {
      mobile: isMobile,
      key: artworkDownload.canvas_key,
      download: true
    });
    window.location.href = artworkDownload.url;
  }

  render() {
    const {
      downloadDialogOpen,
      closeDownloadDialog,
      artworkDownloadComplete,
      artworkDownload,
      registration_path,
      user,
      afterDialogClose,
      isLoading,
      signed_in,
      formError,
    } = this.props;
    const { cropMode, upload } = this.state;

    return (
      <>
        <Transition.Root show={downloadDialogOpen} as={Fragment} appear>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={closeDownloadDialog}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pt-12 sm:pl-10 sm:pt-0">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-0"
                    enterFrom="translate-y-full sm:translate-x-full sm:translate-y-0"
                    enterTo="translate-y-0 sm:translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-0"
                    leaveFrom="translate-y-0 sm:translate-x-0"
                    leaveTo="translate-y-full sm:translate-x-full  sm:translate-y-0"
                  >
                    <Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg  ">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute top-0 right-0 inset-x-1/2 -ml-5 -mt-12 sm:mt-0 sm:left-0 sm:-ml-10 flex pt-2 sm:pt-4 pr-2  sm:pr-4">
                          <button
                            type="button"
                            className="rounded-md text-gray-300 hover:text-white  focus:outline-none"
                            onClick={closeDownloadDialog}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6 hidden sm:block" />
                            <ChevronDownIcon className="h-10 w-10 sm:hidden" />
                          </button>
                        </div>
                      </Transition.Child>
                      <div className="flex h-full flex-col bg-green-500 shadow-xl">
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="relative px-4 pt-10 sm:px-6 mt-2">
                              {artworkDownloadComplete ? (
                                <>
                                  <div className="text-center sm:text-left mb-8">
                                    <h2 className="text-4xl tracking-tight font-bold text-cream sm:block sm:text-4xl">
                                      Well played!
                                    </h2>
                                    <p className="text-cream sm:block mt-1">
                                      {isMobile
                                        ? "You can now share/save your file. Don't be shy."
                                        : "You can now download your file."}{" "}
                                      You'll also shortly receive an email with
                                      the file attached.
                                    </p>
                                    {isMobile ? (
                                      <button
                                        type="button"
                                        onClick={this.sharePanel}
                                        className="px-4 py-2.5 inline-block mt-5 font-bold rounded-md text-green-500 bg-yellow-500 hover:bg-yellow-600"
                                      >
                                        {isLoading ? (
                                          <Preloader addClasses="inline -ml-1 mr-3 h-6 w-6 text-green-500" />
                                        ) : (
                                          <ArrowUpTrayIcon className="inline h-5 w-5 mr-2" />
                                        )}
                                        Share or Save{" "}
                                        {artworkDownload.resource_type}
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={this.redirectToFile}
                                        className="px-4 py-2.5 inline-block mt-5 font-bold rounded-md text-green-500 bg-yellow-500 hover:bg-yellow-600"
                                      >
                                        <ArrowDownTrayIcon className="inline h-5 w-5" />{" "}
                                        Download {artworkDownload.resource_type}
                                      </button>
                                    )}

                                    <div className="rounded-md bg-green-600 p-4 mt-10 mb-4">
                                      <div className="text-cream sm:block mt-1">
                                        <div className="text-lg font-bold text-yellow-500 mb-2">
                                          <FontAwesomeIcon icon={faHandWave} className="mr-2"/>
                                          We want your feedback!
                                        </div> 
                                        Template ideas, use cases, and general feedback are all welcome.
                                      </div>
                                      <a
                                        href="/contact"
                                        className="px-4 py-2.5 inline-block mt-5 font-bold rounded-md text-green-500 bg-yellow-500 hover:bg-yellow-600"
                                      >
                                        Send feedback
                                        <FontAwesomeIcon icon={faArrowRight} />
                                      </a>
                                    </div>
                                  </div>

                                  {/* <div className="rounded-md bg-green-600 p-4 mt-10 mb-4">
                                    <div className="flex">
                                      <div className="flex-shrink-0">
                                        <FontAwesomeIcon
                                          icon={faHighDefinition}
                                          className="h-6 w-6 relative top-1 text-yellow-500"
                                        />
                                      </div>
                                      <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="sm:text-lg font-bold text-yellow-500">
                                          {artworkDownload.resource_type ==
                                          "image"
                                            ? "Download and print this masterpiece"
                                            : "Download in HD"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="flex">
                                      <div className="flex-1 md:flex md:justify-between flex-col">
                                        <p className="text-xs sm:text-sm text-green-200 mt-4">
                                          <CheckIcon className="inline h-5 w-5" />{" "}
                                          {artworkDownload.resource_type ==
                                          "image"
                                            ? "High resolution, print-ready download"
                                            : "HD Resolution (1080p), ready to share"}
                                          <br />
                                          <CheckIcon className="inline h-5 w-5" />{" "}
                                          No Caddisnap logo
                                          <br />
                                          <CheckIcon className="inline h-5 w-5" />{" "}
                                          One-time $5 cost
                                        </p>

                                        <button
                                          type="button"
                                          onClick={
                                            this.props.goToStripeCheckout
                                          }
                                          className="px-4 py-2.5 w-full mt-5 font-bold rounded-md bg-cream text-green-500 hover:bg-green-100"
                                        >
                                          {isLoading ? (
                                            <Preloader addClasses="inline -ml-1 mr-3 h-6 w-6 text-green-500  fill-cream" />
                                          ) : (
                                            <ShoppingBagIcon className="inline h-5 w-5 mr-2" />
                                          )}
                                          Get it now - $5 USD
                                        </button>
                                      </div>
                                    </div>
                                  </div> */}

                                  <div className="rounded-md bg-green-600 p-4 mt-5 mb-4">
                                    <div className="flex">
                                      <div className="flex-shrink-0">
                                        <FontAwesomeIcon
                                          icon={faRectanglePro}
                                          className="h-6 w-6 relative top-1 text-yellow-500"
                                        />
                                      </div>
                                      <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="text-lg font-bold text-yellow-500">
                                          PRO plan
                                        </p>
                                        <span className="text-sm text-green-200">
                                          <ClockIcon className="inline h-4 w-4" />{" "}
                                          Coming soon...
                                        </span>
                                      </div>
                                    </div>
                                    <p className="text-xs sm:text-sm text-green-200 mt-4">
                                      <CheckIcon className="inline h-5 w-5" />{" "}
                                      Unlimited high-resolution downloads
                                      <br />
                                      <CheckIcon className="inline h-5 w-5" />{" "}
                                      Remove Caddisnap logo
                                      <br />
                                      <CheckIcon className="inline h-5 w-5" />{" "}
                                      Exclusive Pro templates
                                      <br />
                                      <CheckIcon className="inline h-5 w-5" />{" "}
                                      Longer maximum video length
                                    </p>
                                    {/* <a
                                      href={`${registration_path}?email=${user.email}&accepts_marketing=${user.accepts_marketing}`}
                                      className="px-4 py-2.5 inline-block mt-5 text-base font-bold rounded-md text-cream bg-green-500 hover:bg-green-400"
                                    >
                                      Create an account
                                    </a> */}
                                  </div>

                                  <div className="rounded-md bg-green-600 p-4 mt-5 mb-4">
                                    <div className="flex">
                                      <div className="flex-shrink-0">
                                        <FontAwesomeIcon
                                          icon={faGolfFlagHole}
                                          className="h-6 w-6 relative top-1 text-yellow-500"
                                        />
                                      </div>
                                      <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="text-lg font-bold text-yellow-500">
                                          Caddisnap for Clubs
                                        </p>
                                        <span className="text-sm text-green-200">
                                          <ClockIcon className="inline h-4 w-4" />{" "}
                                          Coming soon...
                                        </span>
                                      </div>
                                    </div>
                                    <p className="text-xs sm:text-sm text-green-200 mt-4">
                                      <CheckIcon className="inline h-5 w-5" />{" "}
                                      Custom branded templates with your
                                      logo/colours
                                      <br />
                                      <CheckIcon className="inline h-5 w-5" />{" "}
                                      Dedicated landing page for your club
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <h2 className="text-3xl tracking-tight font-bold text-cream sm:block sm:text-4xl">
                                    Last step...
                                  </h2>
                                  <p className="text-cream sm:block mt-1">
                                    Enter your email to share your artwork.
                                  </p>
                                  <form>
                                    <div className="mt-5 w-full">
                                      <label
                                        htmlFor="email-address"
                                        className="sr-only"
                                      >
                                        Email address
                                      </label>
                                      <input
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        value={this.state.email}
                                        onChange={(e) =>
                                          this.onFormChange({
                                            email: e.target.value,
                                          })
                                        }
                                        autoFocus={true}
                                        required
                                        className="w-full px-5 py-3 placeholder-gray-500 focus:ring-green-500 focus:border-green-500  rounded-md"
                                        placeholder="Enter your email"
                                      />
                                      {formError && (
                                        <div className="bg-red-700 text-xs p-1 rounded m-1 text-cream">
                                          {formError}
                                        </div>
                                      )}

                                      <div className="flex items-center mt-3">
                                        <input
                                          id="remember-me"
                                          name="remember-me"
                                          type="checkbox"
                                          checked={this.state.accepts_marketing}
                                          onChange={(e) =>
                                            this.onFormChange({
                                              accepts_marketing:
                                                e.target.checked,
                                            })
                                          }
                                          className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                                        />
                                        <label
                                          htmlFor="remember-me"
                                          className="ml-2 block text-sm text-green-200"
                                        >
                                          Keep me in the loop with Caddisnap
                                          updates.
                                        </label>
                                      </div>
                                      <div className="mt-5 rounded-md">
                                        <button
                                          type="submit"
                                          onClick={this.onSubmitForm}
                                          className="block px-5 py-3 w-full sm:w-auto border border-transparent text-base font-bold rounded-md text-green-500 bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                        >
                                          {isLoading ? (
                                            <React.Fragment>
                                              <Preloader addClasses="inline -ml-1 mr-3 h-6 w-6 text-green-500" />
                                              Sending it
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              Save
                                            </React.Fragment>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-center"></div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default GuestDownloadDialog;
