import React from "react";
import { Fragment } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { Transition, Dialog } from "@headlessui/react";
import HoverVideoPlayer from "react-hover-video-player";
import { isMobile } from "react-device-detect";
import Masonry from "@mui/lab/Masonry";
import {
  ArrowRightIcon,
  CheckIcon,
  ChevronDownIcon,
  DocumentPlusIcon,
  FilmIcon,
  ScissorsIcon,
  ShareIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardListCheck,
  faKeyboardDown,
  faMegaphone,
  faPhotoFilm,
  faShareNodes,
  faTextSize,
} from "@fortawesome/pro-solid-svg-icons";

class CanvasBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currLayout: props.layouts[0],
      currentTemplate: null,
      templateModalOpen: false,
    };
    this.onLayoutSelect = this.onLayoutSelect.bind(this);
    this.onTemplateSelect = this.onTemplateSelect.bind(this);
  }

  onLayoutSelect(layout) {
    this.setState({ currLayout: layout });
  }

  onTemplateSelect(item) {
    this.setState({
      currentTemplate: item,
      templateModalOpen: true,
    });
    analytics.track("Template Previewed", { key: item.key });
  }

  render() {
    // const filteredCanvases = this.props.canvases.filter((canvas) => {
    //   return canvas.layout_id == this.state.currLayout.id
    // })
    const { currentTemplate, templateModalOpen } = this.state;

    const filteredCanvases = this.props.canvases;

    return (
      <div className="max-w-6xl sm:mx-auto mt-8 sm:px-6 pb-8">
        {currentTemplate && (
          <TemplatePreviewModal
            templateModalOpen={templateModalOpen}
            closeDialog={(e) => this.setState({ templateModalOpen: false })}
            currentTemplate={currentTemplate}
            current_score_card={this.props.current_score_card}
            tracker_logo={this.props.tracker_logo}
          />
        )}

        <div className="">
          <Masonry columns={{ xs: 2, sm: 3 }} spacing={{ xs: 2, sm: 3, md: 4 }}>
            {filteredCanvases.map((item, index) => (
              <div
                onClick={(e) => this.onTemplateSelect(item)}
                className="cursor-pointer rounded "
                key={index}
              >
                {item.poster_type == "image" || isMobile ? (
                  <img src={item.poster_image} className="group block w-full" />
                ) : (
                  <HoverVideoPlayer
                    videoSrc={item.poster_url}
                    loop
                    muted
                    controlsList="nodownload"
                    className="group !block w-full"
                  />
                )}
              </div>
            ))}
          </Masonry>
        </div>
        <div className="text-center sm:text-lg my-8">
          <h2 className="text-3xl tracking-tight font-bold mb-2">
            There's more to come.
          </h2>
          We'd love your feedback on shaping the future of Caddisnap.
          <br />
          <a
            href="/contact"
            className="inline-block rounded mt-4 py-3 px-5 text-lg font-bold bg-green-500 text-cream hover:bg-green-700 active:bg-green-800 active:text-cream"
          >
            Get in touch
          </a>{" "}
        </div>
      </div>
    );
  }
}

function TemplatePreviewModal(props) {
  return (
    <Transition.Root show={props.templateModalOpen} as={Fragment} appear>
      <Dialog as="div" className="relative z-50" onClose={props.closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pt-12 sm:pl-10 sm:pt-0">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-0"
                enterFrom="translate-y-full sm:translate-x-full sm:translate-y-0"
                enterTo="translate-y-0 sm:translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-0"
                leaveFrom="translate-y-0 sm:translate-x-0"
                leaveTo="translate-y-full sm:translate-x-full  sm:translate-y-0"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 inset-x-1/2 -ml-5 -mt-12 sm:mt-0 sm:left-0 sm:-ml-10 flex pt-2 sm:pt-4 pr-2  sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white  focus:outline-none"
                        onClick={props.closeDialog}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6 hidden sm:block" />
                        <ChevronDownIcon className="h-10 w-10 sm:hidden" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 relative">
                          <div className="">
                            <div
                              className={classNames(
                                props.currentTemplate.ratio_class,
                                "sm:basis-3/5 bg-white m-4 pb-0"
                              )}
                            >
                              {props.currentTemplate.poster_type == "image" ? (
                                <img
                                  src={props.currentTemplate.poster_image}
                                  className="shadow-lg"
                                />
                              ) : (
                                <video
                                  className=""
                                  src={props.currentTemplate.poster_url}
                                  poster={props.currentTemplate.poster_image}
                                  controls={false}
                                  autoPlay
                                  muted
                                  playsInline
                                  loop={true}
                                />
                              )}
                            </div>
                            <div className="mt-6 px-4 sm:px-6">
                              {props.currentTemplate.requires_score_card && (
                                <div className="relative mb-5">
                                  <FontAwesomeIcon
                                    icon={faClipboardListCheck}
                                    className="absolute h-6 w-6 text-green-500"
                                  />
                                  {props.current_score_card ? (
                                    <>
                                      <p className="ml-9 text-base leading-6 font-bold text-gray-900">
                                        {props.current_score_card} scorecard
                                        ready
                                      </p>
                                      <div className="mt-1 ml-9 text-xs text-gray-500">
                                        Apply, edit or add new scores easily
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <p className="ml-9 text-base leading-6 font-bold text-gray-900">
                                        Add your scores
                                      </p>

                                      <div className="mt-1 ml-9 text-xs text-gray-500">
                                        Any course in the world.
                                      </div>
                                    </>
                                  )}
                                  <div className="bg-green-50 rounded p-4 mt-4 ml-5">
                                    <div className="flex justify-between">
                                      <p className="text-sm leading-6 font-bold text-green-500">
                                        Using a score tracker?
                                      </p>
                                      <img
                                        src={props.tracker_logo}
                                        className="w-36 self-end -mt-3"
                                      />
                                    </div>
                                    <div className="mt-2 text-xs text-gray-500">
                                      Forward your scorecard emails from
                                      Golfshot or Hole19 to scores@caddisnap.com
                                      and we'll load them into Caddisnap for
                                      you.
                                    </div>
                                  </div>
                                </div>
                              )}

                              {props.currentTemplate.requires_upload && (
                                <div className="relative mb-5">
                                  <FontAwesomeIcon
                                    icon={faPhotoFilm}
                                    className="absolute h-6 w-6 text-green-500"
                                  />
                                  <p className="ml-9 text-base leading-6 font-bold text-gray-900">
                                    Use your own photo or video
                                  </p>
                                  <div className="mt-1 ml-9 text-xs text-gray-500">
                                    All templates allow you to upload your own
                                  </div>
                                </div>
                              )}

                              {props.currentTemplate.requires_free_text && (
                                <div className="relative mb-5">
                                  <FontAwesomeIcon
                                    icon={faTextSize}
                                    className="absolute h-6 w-6 text-green-500"
                                  />
                                  <p className="ml-9 text-base leading-6 font-bold text-gray-900">
                                    Customize text fields
                                  </p>
                                  <div className="mt-1 ml-9 text-xs text-gray-500">
                                    {!props.currentTemplate
                                      .requires_score_card &&
                                      "No scorecard required for this template"}
                                  </div>
                                </div>
                              )}

                              <div className="relative mb-5">
                                <FontAwesomeIcon
                                  icon={faShareNodes}
                                  className="absolute h-6 w-6 text-green-500"
                                />
                                <p className="ml-9 text-sm leading-6 font-bold text-gray-900">
                                  Download and share anywhere
                                </p>
                                <div className="mt-1 ml-9 text-xs text-gray-500">
                                  Or keep it private...
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-center">
                      <div className="p-4 w-full">
                        <a
                          href={props.currentTemplate.url}
                          className="text-cream bg-green-500 text-center px-4 py-3 rounded-md font-bold text-base block w-full"
                        >
                          <ScissorsIcon className="inline h-4 w-4" /> Customize
                          this template
                        </a>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function LayoutSelector(props) {
  return (
    <div className="p-5">
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {props.layouts.map((layout) => (
            <button
              key={layout.id}
              onClick={(e) => props.onLayoutSelect(layout)}
              className={classNames(
                layout == props.currLayout
                  ? "bg-gray-100 text-gray-700"
                  : "text-gray-500 hover:text-gray-700",
                "px-3 py-2 font-bold text-xs rounded-md text-cursor"
              )}
            >
              {layout.title}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}

export default CanvasBrowser;
